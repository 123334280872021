import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';
import { Store } from '@ngrx/store';
import { LocalStorageService } from 'src/modules/shared/services/local-storage.service';
import { IAuthState } from 'src/modules/authentication/+store/auth.state';
import { AuthInterceptor } from './auth/auth.interceptor';
import { BaseUrlInterceptor } from './base-url';
import { ConfigService } from 'src/app/services/config.service';



const authInterceptorFactory = (storage: LocalStorageService, store: Store<IAuthState>, configService: ConfigService) => new AuthInterceptor(storage, store, configService);

@NgModule({})
export class InterceptorsModule {
    static forRoot(): ModuleWithProviders<InterceptorsModule> {
        return {
            ngModule: InterceptorsModule,
            providers: [
                { useFactory: authInterceptorFactory, provide: HTTP_INTERCEPTORS, deps: [LocalStorageService, Store, ConfigService], multi: true },
                { useClass: BaseUrlInterceptor, provide: HTTP_INTERCEPTORS, multi: true },
            ]
        };
    }
}
<div *ngIf = "isStaffer" [ngClass] = "{'staffer' : isStaffer}">
  <ion-header [ngClass] = "{ 'client' : !isStaffer, 'toggleColor' : subTitle}"> 
  <ion-toolbar class="toolbar">
    <ion-buttons *ngIf="showBackButton" slot="start" (click)="navBack()">
      <i class = "fa fa-arrow-left"></i><span class = "back-txt">Back</span>
    </ion-buttons>
    <ion-title class = "sub-title">{{ subTitle }}</ion-title>
  </ion-toolbar>
</ion-header>
</div>

<div *ngIf = "!isStaffer">
  <ion-header [ngClass] = "{ 'client' : !isStaffer, 'generic' : !userData?.companyType, 'hospitality': userData?.companyType === 'Hospitality'}">
    <ion-toolbar class="toolbar">
      <ion-buttons *ngIf="showBackButton" slot="start" (click)="navBack()">
        <ion-icon slot="icon-only" style='color:white' name="arrow-back"></ion-icon>
      </ion-buttons>
      <ion-buttons *ngIf="(showMenu | async)" slot="start">
        <ion-menu-button>
          <i class="fas fa-bars menu-icon"></i>

        </ion-menu-button>

      </ion-buttons>
      <ion-title class="title ion-text-center" [ngClass] = "{'extra-right-margin' : !(showNotification | async) && (showMenu | async)}">
        <img class="ion-text-center" [src] = "staffyLogo" alt="logo">
      </ion-title>
      <ion-buttons *ngIf="(showNotification | async)" slot="end" (click)="openNotification()">
        <img class="ion-text-center notification" [src]="staffyIcon" alt="icon">
      <span class="badge label-danger notification-count-bg">
        {{notificationCount}}
        </span>

      </ion-buttons>
    </ion-toolbar>
  </ion-header>
</div>
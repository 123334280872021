<div>
<div class = "slide-section" *ngIf = "stafferCertificates.length">
  <div class = "slides">
    <ion-slides [options] = "slideConfig" #slider (ionSlideTouchEnd)  = "slideTouchEnd()" (ionSlideDidChange) = "slideChanged()">
        <ion-slide  *ngFor = "let item of stafferCertificates; let i = index" [ngClass] = "{'current-slide' : i == currentSlide, 'inital-slide' : i == 0}">
        

          <!-- Show certificate image, If certificate is uploaded as an image -->
          <div class="fixed-container" *ngIf = "!item.isCertificatePDF && !item.isCertificateDOC">
            <img class="object-fit-cover"  *ngIf = "item.isCertificateImage" src =  {{item.url}}>
          </div>

          <!-- Show certificate pdf, If certificate is uploaded as a pdf -->
          <div *ngIf = "item.isCertificatePDF" class = "full-width">
            <div class = "box">
              <div *ngIf="showLoading" class = "loader-div">
                <img class="loading-img" src="assets/images/loading.gif" alt="logo">
              </div>

              <pdf-viewer class = "pdf-viewer" [ngClass] = "{'blurCertificate' : showLoading}" [src]="item.sanitizeURL.changingThisBreaksApplicationSecurity" [show-all] = "true"
                [autoresize]="false" [original-size]="false" [show-borders]="false" 
                (text-layer-rendered)="textLayerRendered($event)" (error)="onError($event)" (on-progress)="onProgress($event)">
              </pdf-viewer>
            </div>  
            <a class = "view-certificate-link" (click)= "openLink(item.url)">View Credential File</a>
          </div>

          <!-- Show certificate document, If certificate is uploaded as a document -->
          <div *ngIf = "item.isCertificateDOC" class = "full-width">
            <div class = "box">
              <p class = "document-txt">Document File encounterd. Please <a (click)= "openLink(item.url)">Click here</a> to view credential.</p>
            </div>
          </div>


          <!-- Show issue/expiry dates/delete button -->
          <div *ngIf = "item.markCertificateAsRejected.isRejected" class = "reupload"> 
            <i class = "fa fa-times"></i>
            <span>Not approved</span>
            <span (click) = "addCertificates(true, item._id)" class = "retake-photo">Retake photo</span>
            <i (click) = "showReasonOfRejection(item)" class="fa fa-info-circle" aria-hidden="true"></i>
          </div>

          <!-- Show issue date or expiry date eneterd by contractor based upon their requirement as per certificate -->
          <div *ngFor = "let specs of dateSpecifications">
          <label *ngIf = "item?.issueDate && specs?.key === 'issueDate'" class = "delete-cert padding-top-10">Issue Date: {{ item.issueDate | momentPipeUnix: 'MMM DD, YYYY' }} </label>
          <label *ngIf = "item?.expiryDate && specs?.key === 'expiryDate'" class = "delete-cert padding-top-10">Expiry Date: {{ item.expiryDate | momentPipeUnix: 'MMM DD, YYYY' }} </label>
          
          <div class = "top-10" *ngIf = "!item?.markCertificateAsRejected?.isRejected && !item?.isApprove">
            <label class = "delete-cert padding-top-10 padding-bottom-10">
              <i class = "fas fa-edit"></i>
              <span (click) = "editDateForCertificate(item, specs.key)">Edit {{ specs.displayName}}</span>
            </label>
          </div>
        </div>
        
        <p *ngIf = "enableDeleteButton(item)" class = "delete-cert" (click) = "showDeleteCertificatePopup(item)"><i class ="fa fa-trash"></i>Delete Credential</p>
          
      </ion-slide>

    </ion-slides>              
  </div>
  <div *ngIf="stafferCertificates.length >=1" class = "add-more">
    <ion-button (click)="addCertificates(false)"><i class = "fas fa-plus"></i></ion-button>
  </div>  
</div>

<div class = "add-more back">
  <ion-button *ngIf="stafferCertificates.length > 1" (click)="addCertificates(false)" class = "done" style="width:auto !important">Add Another Page</ion-button>
  <ion-button (click)="navigateToUpload()" class = "done">Done</ion-button>
</div>
</div>
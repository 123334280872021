import { Component, OnInit, Input, OnChanges, ViewChild, EventEmitter, Output, AfterViewInit, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { BroadcastJobsUtilService } from '../../../company-dashboard/services/broadcast-job-utils.service';
import { IDatePickerConfig, DatePickerDirective, ECalendarValue } from 'ng2-date-picker';
import * as moment from 'moment-timezone';
import * as _ from 'lodash';

import { Keyboard } from '@ionic-native/keyboard/ngx';
import { ITeam } from '../../../company-dashboard/interfaces/team.interface';
import { IUserAddress } from 'src/modules/authentication/interfaces/user-address.interface';
import { IUser } from 'src/modules/authentication/interfaces/user.interface';
import { IBroadcastInitialData } from '../../../company-dashboard/interfaces/broadcast-initial-data.interface';
import { PopupService } from 'src/modules/shared/services/popup.service';
import { filter, orderBy, isEmpty, map } from 'lodash';
import { UserService } from '../../services/user.service';
import { Store } from '@ngrx/store';
import { IAuthState } from 'src/modules/authentication/+store/auth.state';
import { getUserInfoAction } from 'src/modules/authentication/+store/actions/auth.actions';
import { ModalController } from '@ionic/angular';
import { HomecarePatient } from '../../interfaces/homecare-patient.interface';
import { ITask } from 'src/modules/authentication/interfaces/task.interface';
import { UtilService } from '../../services/util.services';
import { TalentDashboardService } from 'src/modules/talent-dashboard/services/talent-dashboard.service';
import { ISetting } from 'src/modules/talent-dashboard/interfaces/settings.interface';
import { CompanyTypeEnum } from '../../../onboarding-company/enums/company-type.enum';
import { ICertificateRequirement } from 'src/modules/company-dashboard/interfaces/certificateRequirement.interface';
import { UnpaidBreak } from '../../enums/unpaid-break.enum';

@Component({
  selector: 'shared-broadcast-card',
  templateUrl: './broadcast-card.component.html',
  styleUrls: ['./broadcast-card.component.scss'],
})
export class BroadcastCardComponent implements OnInit, OnChanges {
  showMods: boolean = true;
  isMutliplePatientSelectionAllowed : boolean = false
  multipleDates = [];
  eventType: 'single' | 'multiple' = 'single';
  isTrainingShift: boolean = false;
  setDifferentTime: boolean;
  selectedSkill: string; // skill

  allowRateChange: boolean = false;
  selectedSubSkills: string[] = []; // skill
  vacancies: number; // vacancies
  rate: { id: number, label: string }; // job selected rate
  listOfRates = []; // rate list
  startTimeValues = []; // start time list
  endTimeValues = [];  // end time list
  mydates =  [];
  singleDayStartTime: {
    value: number, formatted: string
  }; // start time of a day
  singleDayEndTime: {
    value: number, formatted: string
  }; // end time of a day
  selectedSingleDate: string | moment.Moment; // date picker single date
  selectedMultipleDates: string[]; // date picker multiple dates
  selectedDates = [];
  multipleTimingsValues: {  // multiple dates, seprate timings
    date: string,
    startTime: {
      value?: number, formatted?: string
    }, endTime: {
      value?: number, formatted?: string
    },
    startTimeValues: Array<any>,
    endTimeValues: Array<any>,
    unpaidBreak: UnpaidBreak
  }[];

  foodHandlerCertification: boolean; // certification starts
  smartServerCertification: boolean;
  CFVCertificate: boolean;
  IRCertificate: boolean;
  CPRCertification: boolean;
  VSCCertificate: boolean;;
  TBTCertificate: boolean;
  N95Certificate: boolean;
  otherCertificate: boolean;
  FSCertificate: boolean;
  foodSafe: boolean;
  license: boolean;
  OPSWACertification: boolean;
  HACDCertification: boolean;
  HKACeertification: boolean; // certification end
  shiftNotes: string;  // shiftNotes
  singleDaySelectconfig: IDatePickerConfig;
  multipleDaySelectconfig: IDatePickerConfig;
  eventName: string;
  unpaidBreak: UnpaidBreak;
  addressType: 'existing' | 'new' = 'existing';
  travelTime: string;
  broadCastToTeam = false;
  isTipIncluded = false;
  individualShifts: boolean;
  selectedTeam: ITeam;
  selectedCompanyAddress: IUserAddress;
  newformattedAddress: IUserAddress;
  subSkillOptions: Array<String>;
  defaultImages: {
    name: string,
    url: string,
    isSelected: boolean,
    showImage: boolean,
  }[] = [];
  startIndex: number;
  endIndex: number;
  isHomeCareClient = false;
  availableTasks: ITask[] = [];
  availablePatients: HomecarePatient[] = [];
  showAddTaskBar = false;

  ////////////////
  // google places
  @ViewChild('googlePlaces', { static: false }) googlePlaceInput;
  formattedAddress: string;
  googlePlace: any;
  activatedMonth: string;
  activatedYear: string;
  dates = [];
  extraColumnDesktop = [];
  extraColumnMobile = [];
  currentActivatedMoment: moment.Moment;
  certificateRequired: ICertificateRequirement[] = [];

  certificateRestrictions: [{_id: string, type: string}];
  isCertificateRequired: boolean = false;
  selectedUnitNumber: string = ''; // skill
  unitNumbers: string[] = [];
  disclaimer: string = '';
  settings: ISetting;
  colSize: number = 6;

  ////////////////////
  @Input() isDirectHire?= false;
  @Input() index: number;
  @Input() initialData: IBroadcastInitialData;
  @Input() industries: string[];

  @Input() subSkills: Array<{ name: string } >;
  @Input() teams: ITeam[];
  @Input() userData: IUser;
  @Input() companyType: string;
  @Input() allAddressesOfCompany: IUserAddress[] = [];
  @Input() defaultRates: { [key: string]: number };
  @Input() tz: string;
  @Input() averageRate: number

  @ViewChild('dateDirectivePicker', { static: false }) datePickerDirective: DatePickerDirective;

  @Output() valueChanges = new EventEmitter<IBroadcastInitialData>();
  @Output() newAddressAdded = new EventEmitter<IUserAddress>();
  @Output() removeShiftFromArray = new EventEmitter<{ index: number }>();
  isMapLoaded = false;
  constructor(
    private broadcastJobsUtilService: BroadcastJobsUtilService,
    private popupService: PopupService,
    private keyboard: Keyboard,
    private talentDashboardService: TalentDashboardService,
    private cdr: ChangeDetectorRef
  ) {
    this.singleDaySelectconfig = {
      allowMultiSelect: false,
      format: 'MM/DD/YYYY',
      closeOnSelect: true,
      showGoToCurrent: true,
      min: moment(),
      disableKeypress: true,
    };

    this.multipleDaySelectconfig = {
      allowMultiSelect: true,
      format: 'MM/DD/YYYY',
      closeOnSelect: false,
      showGoToCurrent: true,
      min: moment(),
      hideOnOutsideClick: true,
      disableKeypress: true,
      returnedValueType: ECalendarValue.StringArr
    };
  }



  async ngOnInit() {
    this.defaultImages = [];
    this.defaultImages = this.getDefaultImages();
    this.multipleDates = [];
    this.dates = [];
    this.colSize = 6;
    this.getStartandEndTimeForDay(
      moment(this.initialData.selectedSingleDate, 'MM/DD/YYYY').tz(this.tz).format(),
      moment(this.initialData.selectedSingleDate, 'MM/DD/YYYY').tz(this.tz).format()
    );

    this.selectedSkill = this.initialData.selectedSkill;

    // this.allowRateChange = this.initialData.allowChange;

    this.selectedSubSkills = this.initialData.selectedSubSkills;

    this.vacancies = this.initialData.noOfVacancy;
    this.selectedSingleDate = this.initialData.selectedSingleDate;
    this.selectedMultipleDates = this.initialData.selectedMultipleDates ? this.initialData.selectedMultipleDates : [];
    //Call get rates while initializing
    this.getRates();
    this.makeCalendar();
    this.selectedCompanyAddress = this.initialData.selectedCompanyAddress;
    this.shiftNotes = this.initialData.shiftNotes || "";
    this.foodHandlerCertification = this.initialData.foodHandlerCertification;
    this.smartServerCertification = this.initialData.smartServerCertification;
    this.eventName = this.initialData.eventName;
    this.selectedTeam = this.initialData.selectedTeam;
    this.broadCastToTeam = this.initialData.broadCastToTeam;
    this.isTipIncluded = this.initialData.isTipIncluded;
    this.unpaidBreak = this.initialData.unpaidBreak ? this.initialData.unpaidBreak : UnpaidBreak.EMPTY;
    this.travelTime = this.initialData.travelTime ? this.initialData.travelTime : '';
    this.eventType = this.initialData.eventType ? this.initialData.eventType : 'single';
    // this.setDifferentTime = this.initialData.setDifferentTime ? this.initialData.setDifferentTime : false;
    // this.selectedMultipleDates = this.initialData.selectedMultipleDates ? this.initialData.selectedMultipleDates : [];
    this.certificateRequired = this.initialData.certificateRequired;
    this.singleDayStartTime = this.startTimeValues[28];
    // this.singleDayEndTime = this.endTimeValues[60];
    //Pass the index to display default time
    this.populateEndTimeList(31);

    this.settings = await this.talentDashboardService.getSettings();
    this.unpaidBreak = this.setUnpaidBreakDropdown(this.singleDayEndTime, this.singleDayStartTime);
    this.valueChanges.emit({
      ...this.initialData,
      singleDayStartTime: this.singleDayStartTime,
      singleDayEndTime: this.singleDayEndTime,
      unpaidBreak: this.unpaidBreak
    });

    this.unitNumbers = this.userData.entityId.unitNumber;
    if(this.subSkills) {
      this.subSkillOptions = this.subSkills.map(item => item.name);
      this.subSkillOptions = this.userData.entityId.excludedSubSkills
        ? this.subSkillOptions.filter(
          skill => !this.userData.entityId.excludedSubSkills.includes(skill)
        )
        : this.subSkillOptions;
    }
    this.disclaimer = this.userData.entityId.disclaimer || ""
    
    if (this.userData.entityId.homecareClient && this.userData.entityId.homecareClient.isTrue) {
      this.isHomeCareClient = true;
      this.availableTasks = [...this.userData.entityId.homecareClient.tasks];
      this.availablePatients = [...this.userData.entityId.homecareClient.patients];
    }

   this.isMutliplePatientSelectionAllowed = this.userData.entityId.enableMutliplePatientSelection || false

  }


  ngOnChanges(): void {
    if (this.userData && this.userData.entityId.homecareClient && this.userData.entityId.homecareClient.isTrue) {
      this.availableTasks = [...this.userData.entityId.homecareClient.tasks];
    }
  }

  onMonthBack() {
    const previousMonth = moment(`${this.activatedMonth}-${this.activatedYear}`, 'MMMM-YYYY').subtract(1, 'months');
    if ((previousMonth.format('MMMM') != moment().format('MMMM')) && moment(previousMonth).isBefore(moment())) {
      // if month is past, return
      return;
    }
    this.currentActivatedMoment = moment(this.currentActivatedMoment).subtract(1, 'months');
    this.activatedMonth = moment(this.currentActivatedMoment).format('MMMM');
    this.activatedYear = moment(this.currentActivatedMoment).format('YYYY');
    this.getDates();
    this.currentDayActive();
  }

  makeCalendar() {
    this.currentActivatedMoment = moment();
    this.activatedMonth = moment().format('MMMM');
    this.activatedYear = moment().format('YYYY');
    this.selectedSingleDate = moment().format('MM/DD/YYYY');
    this.getDates();
    this.currentDayActive();
  }

  currentDayActive() {
    for(let i = 0; i < this.dates.length; i++) {
      if(this.eventType == 'single' && this.dates[i].dateMoment.format('MM/DD/YYYY') == this.selectedSingleDate) {
        this.dates[i].isSelected = true; 
      }  else if(this.eventType == 'single' && this.selectedMultipleDates.length) {
          this.dates[i].isSelected = false;
      } else if(this.eventType == 'multiple' && this.dates[i].dateMoment.format('MM/DD/YYYY') == this.selectedSingleDate && this.selectedMultipleDates.length == 0) {
        this.dates[i].isSelected = false;  
      } else if(this.eventType == 'multiple' && this.selectedMultipleDates.length > 0) {

      } 
    }
  }

  onMonthNext() {
    this.currentActivatedMoment = moment(this.currentActivatedMoment).add(1, 'months');
    this.activatedMonth = moment(this.currentActivatedMoment).format('MMMM');
    this.activatedYear = moment(this.currentActivatedMoment).format('YYYY');
    this.getDates();
    this.currentDayActive();
  }

  getDates() {


    this.dates = [];
    this.extraColumnDesktop = [];
    this.extraColumnMobile = [];

    const numOfDays = moment(`${this.activatedMonth}-${this.activatedYear}`, 'MMMM-YYYY').daysInMonth();
    
    for (let index = 1; index <= numOfDays; index++) {
      const momentObj = moment(`${index}-${this.activatedMonth}-${this.activatedYear}`, 'DD-MMMM-YYYY');

      const isDisabled = moment().isSame(momentObj, 'day') ? false : momentObj.isBefore(moment())

        this.dates.push({
        date: index,
        dateMoment: momentObj,
        isDisabled,
        isSelected : this.markSelectedStatus(momentObj)
      });
    }

    if(this.activatedMonth == 'January' || this.activatedMonth == 'March' || this.activatedMonth == 'May' || 
    this.activatedMonth == 'July' || this.activatedMonth == 'August' || this.activatedMonth === 
    'October' || this.activatedMonth == 'December') {
      this.extraColumnDesktop.push({
        index: 0
      })
    }

    else if(this.activatedMonth == 'April' || this.activatedMonth == 'June' || this.activatedMonth == 'September' || 
    this.activatedMonth == 'November') {
      for(let i = 0 ; i < 2; i++) {
        this.extraColumnDesktop.push({
          index: i
        })
      }
    }

    else if(!moment([this.activatedYear]).isLeapYear() && this.activatedMonth == 'February' ) {
      for(let i = 0 ; i < 4; i++) {
        this.extraColumnDesktop.push({
          index: i
        })
      }
    }

    else if(moment([this.activatedYear]).isLeapYear() && this.activatedMonth == 'February' ) {
      for(let i = 0 ; i < 3; i++) {
        this.extraColumnDesktop.push({
          index: i
        })
      }
    }


    //Extra columns for mobile

    if(this.activatedMonth == 'January' || this.activatedMonth == 'March' || this.activatedMonth == 'May' || 
    this.activatedMonth == 'July' || this.activatedMonth == 'August' || this.activatedMonth === 
    'October' || this.activatedMonth == 'December') {
      for(let i = 0 ; i < 2; i++) {
        this.extraColumnMobile.push({
          index: i
        })
      }
    }

    else if(this.activatedMonth == 'April' || this.activatedMonth == 'June' || this.activatedMonth == 'September' || 
    this.activatedMonth == 'November') {
      for(let i = 0 ; i < 3; i++) {
        this.extraColumnMobile.push({
          index: i
        })
      }
    }

    else if(!moment([this.activatedYear]).isLeapYear() && this.activatedMonth == 'February' ) {
      for(let i = 0 ; i < 5; i++) {
        this.extraColumnMobile.push({
          index: i
        })
      }
    }

    else if(moment([this.activatedYear]).isLeapYear() && this.activatedMonth == 'February' ) {
      for(let i = 0 ; i < 4; i++) {
        this.extraColumnMobile.push({
          index: i
        })
      }
    }

  }

  markSelectedStatus(momentObj) {

    if(this.eventType == 'multiple' && this.selectedMultipleDates.length > 0) {
      for(let i = 0 ; i < this.selectedMultipleDates.length ; i++) {
        if(this.selectedMultipleDates[i] == momentObj.format('MM/DD/YYYY')) {
          return true;
        } 
      }
    } else {
      return false;
    }
  }

  selectedDate(dateSelected: any) {
    
    if(this.eventType == 'single') {
      for(let i = 0 ; i < this.dates.length ; i++) {
        if(this.dates[i].dateMoment.format('MM/DD/YYYY') == dateSelected.dateMoment.format('MM/DD/YYYY')) {
          this.dates[i].isSelected = true;
          this.selectedSingleDate = dateSelected.dateMoment.format('MM/DD/YYYY');
          this.dateChanged();
        } else {
          this.dates[i].isSelected = false;
        }
      }    
    }

    if(this.eventType == 'multiple') {
      for(let i = 0 ; i < this.dates.length ; i++) {
        if(this.dates[i].dateMoment.format('MM/DD/YYYY') == dateSelected.dateMoment.format('MM/DD/YYYY')) {
            this.dates[i].isSelected = !this.dates[i].isSelected;
            if(this.dates[i].isSelected == true) {
              this.multipleDates.push(dateSelected);
            } else {
               this.multipleDates = this.multipleDates.filter(function(i) {
                  if(i.dateMoment.format('MM/DD/YYYY') != dateSelected.dateMoment.format('MM/DD/YYYY')) {
                    return i;
                  } 
               }) 
            } 
          }
        } 

      
        this.multipleDates = this.multipleDates.filter(function (i) {
          return i.isSelected == true;
        })

        this.multipleDates = orderBy(this.multipleDates, (o: any) => {
           return o.dateMoment.format('MM/DD/YYYY')
        }, ['asc']) 

        
        this.selectedMultipleDates = this.multipleDates.map(function(i) {
          return i.dateMoment.format('MM/DD/YYYY');
        })

        this.multipleDatesChanged();
      }
    }

  compareTime(time: any, selectedTime: any) {
    if (selectedTime) {
      return time.value === selectedTime.value;
    }
    return;
  }

  focus(event) {
    event.stopPropagation();
    setTimeout(() => {
      this.keyboard.hide();
    });
  }

  async setRequiredTrainingSubSkill() {
    if(this.userData.entityId && this.userData.entityId.requiredTrainingSubSkills){
      this.selectedSubSkills = await this.broadcastJobsUtilService.requiredTrainingSubSkill(this.userData.entityId.requiredTrainingSubSkills, this.selectedSkill, this.selectedSubSkills);
    } 
  }

  handleSkillChange() {
    this.getRates();
    this.setRequiredTrainingSubSkill();
    this.valueChanges.emit({
      ...this.initialData,
      selectedSkill: this.selectedSkill,
      rate: this.rate

    });
  }

  vacancyChange() {
    if (this.vacancies) {
      this.valueChanges.emit({
        ...this.initialData,
        noOfVacancy: this.vacancies
      });
    }
  }

  handleDayChange(e, val: 'single' | 'multiple') {
    this.eventType = val;
    if (this.eventType === 'single') {
      this.currentDayActive();
      this.selectedMultipleDates = [];
      this.setDifferentTime = false;
    }

    if(this.eventType == 'multiple') {
      this.currentDayActive();
      this.setDifferentTime = false;
    }
    

    this.valueChanges.emit({
      ...this.initialData,
      eventType: this.eventType,
      setDifferentTime: this.setDifferentTime
    });
  }



  getRates() {
    this.listOfRates = [];
    // minimum rate is the default rate set in skills.json
    const stateDefaultRates = this.defaultRates[this.selectedSkill]['defaultRate'] || 20;
    // Min/Max price of skill
    const minPrice = this.defaultRates[this.selectedSkill]['minPrice'] || stateDefaultRates;
    const maxPrice = this.defaultRates[this.selectedSkill]['maxPrice'] || 100;
    // if entity rate is undefined or equal to default rate, minium rate would be selected for the selected skill
    let rateIndex = 0;
    for (let i = minPrice; i <= maxPrice; i += 0.5) {

      if(this.defaultRates[this.selectedSkill]['rate'] < i && this.defaultRates[this.selectedSkill]['rate'] > (i-0.5)){
        
        this.listOfRates.push({
        id: this.defaultRates[this.selectedSkill]['rate'],
        label: '$' + this.defaultRates[this.selectedSkill]['rate'].toFixed(2)
        });
        
        rateIndex = this.listOfRates.length - 1;
      }
     
      this.listOfRates.push({
        id: i,
        label: '$' + i.toFixed(2),
      });

      // if entity rates are not equal to default rates then we need the entity rates to be selected by default
      if (i === this.defaultRates[this.selectedSkill]['rate']){
        rateIndex = this.listOfRates.length - 1;
      }

    }

    if (this.selectedSkill === 'catering bartender' || this.selectedSkill === 'catering server' ||
      this.selectedSkill === 'event bartender' || this.selectedSkill === 'event server') {
      this.rate = this.listOfRates[5];
    } else {
      this.rate = this.listOfRates[rateIndex];
    }
    
    //Check if client can update rate for the selected skill
    this.canClientUpdateRate();
  }


  canClientUpdateRate() {
    //assign allow change property of skill, if true selected list will be enabled 
    this.allowRateChange = this.defaultRates[this.selectedSkill]['allowChange'];
  }

  dateChanged() {
    if (this.selectedSingleDate !== this.initialData.selectedSingleDate) {

      this.updateTimeView(this.selectedSingleDate);
      this.valueChanges.emit({
        ...this.initialData,
        selectedSingleDate: this.selectedSingleDate,
        singleDayStartTime: this.singleDayStartTime,
        singleDayEndTime: this.singleDayEndTime
      });
    }
  }

  updateTimeView(dateString) {
    
    const date = moment(dateString, 'MM/DD/YYYY').tz(this.tz).format();
    this.getStartandEndTimeForDay(date, date);

    this.startIndex = this.startTimeValues.findIndex((i) => {
      return i.formatted === this.singleDayStartTime.formatted && moment.unix(i.value).tz(this.tz).format('MM/DD/YYYY') === moment(dateString, 'MM/DD/YYYY').tz(this.tz).format('MM/DD/YYYY');
    });
    
    this.endIndex = this.endTimeValues.findIndex((i) => {
      return i.formatted === this.singleDayEndTime.formatted && moment.unix(i.value).tz(this.tz).format('MM/DD/YYYY') === moment(dateString, 'MM/DD/YYYY').tz(this.tz).format('MM/DD/YYYY');
    });
    
    this.singleDayStartTime = this.startTimeValues[this.startIndex];
    this.singleDayEndTime = this.endTimeValues[this.endIndex];
  }

  multipleDatesChanged() {
     if (this.selectedMultipleDates && this.selectedMultipleDates.length) {
      if (this.setDifferentTime) {
        this.setDifferentTimings();
      } else {

        this.updateTimeView(this.selectedMultipleDates[0]);

        this.valueChanges.emit({
          ...this.initialData,
          selectedMultipleDates: this.selectedMultipleDates,
          singleDayStartTime: this.singleDayStartTime,
        singleDayEndTime: this.singleDayEndTime
        });

      }
    }
  }


  async setDifferentTimings() {
    this.updateTimeView(this.selectedMultipleDates[0]);  
    if(!this.multipleTimingsValues) {
      this.multipleTimingsValues = await this.selectedMultipleDates.map((date) => {
      return { // set timelist values
        date,
        startTimeValues: this.broadcastJobsUtilService.generatedStepArray(moment(date, 'MM/DD/YYYY').tz(this.tz).format(), null, true),
        endTimeValues: this.broadcastJobsUtilService.generatedStepArray(moment(date, 'MM/DD/YYYY').tz(this.tz).format()),
        startTime: {},
        endTime: {},
        unpaidBreak: UnpaidBreak.EMPTY
      };
    });

    } else {
      let date = filter(this.selectedMultipleDates, value => this.multipleTimingsValues.findIndex(valName => valName.date == value ) == -1);

      if(date.length > 0) {
            this.multipleTimingsValues.push({
              date: date[0],
              startTimeValues: this.broadcastJobsUtilService.generatedStepArray(moment(date, 'MM/DD/YYYY').tz(this.tz).format(), null, true),
              // endTimeValues: this.broadcastJobsUtilService.generatedStepArray(moment(date, 'MM/DD/YYYY').tz(this.tz).format()),
              endTimeValues: [], //Keep the end time list empty, so it can be populated according to the start time dynamically
              startTime: {},
              endTime: {},
              unpaidBreak: UnpaidBreak.EMPTY
          });
      }
    }

    this.updateTimingsForElements();
    

    this.multipleTimingsValues = filter(this.multipleTimingsValues, value => this.selectedMultipleDates.findIndex(valName => valName == value.date ) >= 0)
    
    this.multipleTimingsValues = orderBy(this.multipleTimingsValues, (o: any) => {
      return o.date
   }, ['asc']) as any 

    if (this.multipleTimingsValues.length < 2) {
      this.popupService.showModal(
        {
          heading: 'Error',
          message: 'Multiple day should be more than one day',
          btn: 'Dismiss',
          navigateRoute: null,
          imgURL: 'assets/images/incomplete.png'
        });
    } 

    this.multipleTimingsValues.forEach(elem => elem.unpaidBreak = this.setUnpaidBreakDropdown(elem.endTime, elem.startTime)) 

      this.valueChanges.emit({
        ...this.initialData,
        setDifferentTime: this.setDifferentTime,
        selectedMultipleDates: this.selectedMultipleDates, 
        multipleTimingsValues: this.multipleTimingsValues
      });
  }

  updateTimingsForElements() {
    this.multipleTimingsValues.forEach((element, index) => {
      element.startTime = isEmpty(element.startTime) ? element.startTimeValues[this.startIndex] : element.startTime;
  
      // Calculate endTimeValues based on startTime
      const startTimeMoment = moment.unix(Number(element.startTime.value)); // Convert startTime to a moment object
  
      // Populate endTimeValues and filter valid end times
      element.endTimeValues = this.broadcastJobsUtilService
        .generatedStepArray(moment(element.date, 'MM/DD/YYYY').tz(this.tz).format())
        .filter(
          (time) =>
            moment.unix(Number(time.value)).isAfter(startTimeMoment) || time.formatted === 'Next Day'
        );
  
      this.endIndex = element.endTimeValues.findIndex((i) => {
        return (
          i.formatted === this.singleDayEndTime.formatted &&
          moment.unix(i.value).tz(this.tz).format('MM/DD/YYYY') ===
            moment(element.date, 'MM/DD/YYYY').tz(this.tz).format('MM/DD/YYYY')
        );
      });
  
      // Set the endTime with the first valid option, or reset it if none exist
      element.endTime = isEmpty(element.endTime) && this.endIndex > 0
        ? element.endTimeValues[this.endIndex]
        : element?.endTimeValues[0]?.value ? element.endTimeValues[0] : element.endTimeValues[1];
      });

  }
  
  handleDifferentTime() {
    this.setDifferentTime = !this.setDifferentTime;
    if(this.setDifferentTime) {
     this.setDifferentTimings();
    } else {
      this.handleStartTimeChange();
      
      this.valueChanges.emit({
      ...this.initialData,
      setDifferentTime: this.setDifferentTime,
      selectedMultipleDates: this.selectedMultipleDates, 
     });
    }
    
  }


  discardDiffTime() {
    this.setDifferentTime = false;
    this.valueChanges.emit({
      ...this.initialData,
      setDifferentTime: this.setDifferentTime
    });
  }

  async addressUpdates() {
    // Google Places API auto complete
    const input = await this.googlePlaceInput.getInputElement();
    const autocomplete = new google.maps.places.Autocomplete(input, { types: [] });
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      // retrieve the place object for your use
      const place = autocomplete.getPlace();
      this.formattedAddress = place.formatted_address;
      this.googlePlace = place;
      const newformattedAddress: IUserAddress = this.broadcastJobsUtilService.getFormatedAddress(this.googlePlace);
      this.newformattedAddress = {
        longitude: newformattedAddress.longitude,
        latitude: newformattedAddress.latitude,
        city: newformattedAddress.city && newformattedAddress.city !== 'undefined'
          && newformattedAddress.city !== null ? newformattedAddress.city : this.userData.entityId.address.city,
        street: newformattedAddress.street,
        province: this.userData.entityId.address.province,
        default: false
      };

      // this.valueChanges.emit({
      //   ...this.initialData,
      //   newformattedAddress: this.newformattedAddress
      // });


    });
  }

  saveNewAddress() {
    
    if (this.newformattedAddress) {
      this.newAddressAdded.emit(
        this.newformattedAddress
      );
      this.newformattedAddress = null;
      this.formattedAddress = null;

    }

  }

  getStartandEndTimeForDay(startVal: string, endVal: string) {
    this.startTimeValues = this.broadcastJobsUtilService.generatedStepArray(startVal, null, true);
    this.endTimeValues = this.broadcastJobsUtilService.generatedStepArray(endVal);
  }


  handleRateChange() {
    this.valueChanges.emit({
      ...this.initialData,
      rate: this.rate
    });
  }

  handleSubSkillChange(selectedOptions) {

    this.selectedSubSkills =  selectedOptions
    this.valueChanges.emit({
      ...this.initialData,
      selectedSubSkills: this.selectedSubSkills
    });
  }

  handleStartTimeChange() {

    //Update end time list according to the selected start time
    this.populateEndTimeList();
    
    this.unpaidBreak = this.setUnpaidBreakDropdown(this.singleDayEndTime, this.singleDayStartTime);
    this.valueChanges.emit({
      ...this.initialData,
      singleDayStartTime: this.singleDayStartTime,
      unpaidBreak: this.unpaidBreak,
        singleDayEndTime: this.singleDayEndTime
    });
  }


  populateEndTimeList(index?: number) {
    const startTimeMoment = moment.unix(this.singleDayStartTime.value); // Assuming `singleDayStartTime.value` is a Unix timestamp
    const minEndTime = startTimeMoment.clone().add(15, 'minutes').format(); // 15 minutes ahead
   
    // Generate possible end times and filter invalid ones
    this.endTimeValues = this.broadcastJobsUtilService
    .generatedStepArray(minEndTime)
    .filter((time) => moment.unix(Number(time.value)).isAfter(startTimeMoment) || time.formatted === 'Next Day'); // Filter values after start time

    // Set the first valid end time or reset if none
    this.singleDayEndTime = index ? this.endTimeValues[index] : this.endTimeValues[0];
  }


  handleEndTimeChange() {
    this.unpaidBreak = this.setUnpaidBreakDropdown(this.singleDayEndTime, this.singleDayStartTime);
    this.valueChanges.emit({
      ...this.initialData,
      singleDayEndTime: this.singleDayEndTime,
      unpaidBreak: this.unpaidBreak
    });
  }

  handleAddressChange(address) {
    this.selectedCompanyAddress = address;
    this.valueChanges.emit({
      ...this.initialData,
      selectedCompanyAddress: this.selectedCompanyAddress
    });
  }

  wrapperClicked() {
    if (this.datePickerDirective) {
      this.datePickerDirective.api.close();
    }
  }

  removeShift() {
    this.removeShiftFromArray.emit({ index: this.index });
  }

  focusOutShiftNote(event) {
    this.valueChanges.emit({
      ...this.initialData,
      shiftNotes: event.target.innerHTML
    });
  }
  changeAddressType(type: 'new' | 'existing') {
    this.addressType = type;
    this.valueChanges.emit({
      ...this.initialData,
      addressType: this.addressType
    });
    if (!this.isMapLoaded) {
      this.addressUpdates()
      this.isMapLoaded = true;
    }
  }

  focusOutEventName() {
    this.valueChanges.emit({
      ...this.initialData,
      eventName: this.eventName
    });
  }

  certificationChange(certification: string) {
    this.valueChanges.emit({
      ...this.initialData,
      foodHandlerCertification: this.foodHandlerCertification,
      smartServerCertification: this.smartServerCertification,
      CFVCertificate: this.CFVCertificate,
      IRCertificate: this.IRCertificate,
      CPRCertification: this.CPRCertification,
      VSCCertificate: this.VSCCertificate,
      TBTCertificate: this.TBTCertificate,
      N95Certificate: this.N95Certificate,
      otherCertificate: this.otherCertificate,
      FSCertificate: this.FSCertificate,
      foodSafe: this.foodSafe,
      license: this.license,
      OPSWACertification: this.OPSWACertification,
      HACDCertification: this.HACDCertification,
      HKACeertification: this.HKACeertification // certification end
    });

  }

  unpaidBreakChange() {
    this.valueChanges.emit({
      ...this.initialData,
      unpaidBreak: this.unpaidBreak
    });
  }

  travelTimeChange() {
    this.valueChanges.emit({
      ...this.initialData,
      travelTime: this.travelTime
    });
  }

  broadCastToTeamChange() {
    this.valueChanges.emit({
      ...this.initialData,
      broadCastToTeam: this.broadCastToTeam
    });
  }

  tipIncludedChange() {
    this.valueChanges.emit({
      ...this.initialData,
      isTipIncluded: this.isTipIncluded
    });
  }

  certificateRequiredChange() {
  
    //Check which certificate requirement is needed in the job broadcast
    this.certificateRestrictions = this.filterRequiredCertificateRestrictions();
    this.valueChanges.emit({
      ...this.initialData,
      certificateRestrictions: this.certificateRestrictions,
    });
  }

  filterRequiredCertificateRestrictions(): [{_id: string, type: string}] {
    let restrictions;
    //Filter only those restrictions that are marked as required for making a broadcast request
    restrictions = this.certificateRequired.reduce((acc, certificate) => {
      if(certificate.isRequired === true) {
        acc.push({_id: certificate._id, type: certificate.type})
      };
      return acc;
    }, []);

    return restrictions;

  }

  selectedTeamChange() {
    this.valueChanges.emit({
      ...this.initialData,
      selectedTeam: this.selectedTeam
    });
  }

  handleMultipleTimeChange(i: number, action: string) {
    const element = this.multipleTimingsValues[i];
  
    // Convert the new startTime to a moment object
    const startTimeMoment = moment.unix(Number(element.startTime.value));
  
    // Calculate the minimum end time (15 minutes ahead of start time)
    const minEndTimeMoment = startTimeMoment.clone().add(15, 'minutes');
  
  
    // If action is 'startTime', reset the endTime to the first valid option
    if (action === 'startTime') {
      
      // Update endTimeValues based on the new start time
      element.endTimeValues = this.broadcastJobsUtilService
      .generatedStepArray(moment(element.date, 'MM/DD/YYYY').tz(this.tz).format())
      .filter(
        (time) =>
          moment.unix(Number(time.value)).isSameOrAfter(minEndTimeMoment)
      );
      element.endTime = element.endTimeValues[0]; // Default to the first valid end time
    }
  
    // Update unpaid break dropdown based on start and end times
    this.multipleTimingsValues[i].unpaidBreak = this.setUnpaidBreakDropdown(
      element.endTime,
      element.startTime
    );
  
    // Emit the updated values
    this.valueChanges.emit({
      ...this.initialData,
      multipleTimingsValues: this.multipleTimingsValues,
    });
  }

  getDefaultImages() {
    return [
      {
        name: 'White Shirt Black Tie',
        url: 'assets/images/white-shirt-black-tie.jpg',
        isSelected: false,
        showImage: true
      }, {
        name: 'Black Shirt Black Tie Black Vest',
        url: 'assets/images/black-shirt-black-tie-black-vest.jpg',
        isSelected: false,
        showImage: false
      }, {
        name: 'Black Shirt Black Tie',
        url: 'assets/images/black-shirt-black-tie.jpg',
        isSelected: false,
        showImage: false
      }, {
        name: 'White Shirt',
        url: 'assets/images/white-shirt.jpg',
        isSelected: false,
        showImage: false
      }, {
        name: 'Black Shirt',
        url: 'assets/images/black-shirt.jpg',
        isSelected: false,
        showImage: false
      }, {
        name: 'White Shirt Black Tie Black Vest',
        url: 'assets/images/white-shirt-black-tie-black-vest.jpg',
        isSelected: false,
        showImage: false
      }, {
        name: 'White Shirt Black Vest',
        url: 'assets/images/white-shirt-black-vest.jpg',
        isSelected: false,
        showImage: false
      }, {
        name: 'White Chef Jacket',
        url: 'assets/images/white-chef-jacket.jpg',
        isSelected: false,
        showImage: false
      }
    ];
  }

  navigateImages(inc: number, index: number) {

    let skillNum = inc + index;
    if (skillNum >= this.defaultImages.length) {
      skillNum = 0;
    }

    if (skillNum < 0) {
      skillNum = inc + this.defaultImages.length;
    }

    for (let i = 0; i < this.defaultImages.length; i++) {
      if (skillNum === i) {
        this.defaultImages[i].showImage = true;
      } else {
        this.defaultImages[i].showImage = false;
      }
    }
  }

  selectImage(index: number) {
    this.defaultImages[index].isSelected = !this.defaultImages[index].isSelected;

    this.valueChanges.emit({
      ...this.initialData,
      uniforms: this.defaultImages
    });
  }

  handleTrainingShiftChange(e) {
    this.isTrainingShift = e.target.checked;
    this.valueChanges.emit({
      ...this.initialData,
      isTrainingShift: this.isTrainingShift,
    });
  }

  handleUnitNumberChange() {
    this.valueChanges.emit({
      ...this.initialData,
      unitNumber: this.selectedUnitNumber,
    });
  }

  handleDisclaimerChange() {
    this.valueChanges.emit({
      ...this.initialData,
      disclaimer: this.disclaimer,
    });
  }

  trackByIndex(index: number): any {
    return index;
  }

  getSelectedTasks(tasks: ITask[], patient: HomecarePatient): void {
    let patients = this.initialData.homecareShift.patients || [];
    let patientIndex = patients.findIndex(item => item.id == patient.id)


    if (patientIndex !== -1) {
      patients[patientIndex] = {
        ...patients[patientIndex],
        tasks
      }
    }
  
    this.valueChanges.emit({
      ...this.initialData,
      homecareShift: {
        ...this.initialData.homecareShift,
        patients : patients,
      }
    });
  }

  handlePatientSelection(patient:Array<HomecarePatient> | HomecarePatient){
    
    if(Array.isArray(patient)){
      patient = patient.filter(item => item.id)
      patient = _.uniqBy(patient,'id')

      if(!patient.length){
        return;
      }
    }

    this.valueChanges.emit({
      ...this.initialData,
      selectedCompanyAddress: Array.isArray(patient) ? patient[0].address : patient.address,
      homecareShift: {
        ...this.initialData.homecareShift,
        patients:  Array.isArray(patient) ? [...patient] : [patient],
      }
    });
  }

  setUnpaidBreakDropdown(endTime, startTime) {
    if(this.companyType == CompanyTypeEnum.HEALTHCARE || this.companyType == CompanyTypeEnum.RETIREMENT_HOME){
      const diffInMilliseconds = moment(endTime.value * 1000).diff(startTime.value * 1000);
      const diffInMinutes = Math.round(moment.duration(diffInMilliseconds).asMinutes());
  
      let unpaidBreak: UnpaidBreak;
      if (this.settings) {
        for (const unpaidBreakConfig of this.settings[0].unpaidBreakConfigs) {
          if (diffInMinutes >= unpaidBreakConfig.shiftTimeInMinutes) {
            unpaidBreak = unpaidBreakConfig.breakTime;
          }
        }
      }
      return unpaidBreak;
    } else{
      return UnpaidBreak.EMPTY;
    }
   
  };
  
  unpaidBreakForDistinctTimes(index, event) {
    this.multipleTimingsValues[index].unpaidBreak =  event.target.value;
    this.valueChanges.emit({
      ...this.initialData,
      multipleTimingsValues: this.multipleTimingsValues
    });
  }

  individualShiftsChange() {
    this.valueChanges.emit({
      ...this.initialData,
      individualShifts: this.individualShifts
    });
  }

}

import { Injectable } from '@angular/core';
import { IUser } from 'src/modules/authentication/interfaces/user.interface';
import { LocalStorageService } from './local-storage.service';
import { CompanyTypeEnum } from 'src/modules/onboarding-company/enums/company-type.enum';
import { SkillFilterationService } from './skill-filteration.service';

@Injectable({ providedIn: 'root' })
export class StaffyRebrandingService {
    constructor(
        private localStorageService: LocalStorageService,
        private skillFilterationService: SkillFilterationService
    ) {}

    async fetchUserExpertise(user: IUser): Promise<string> {
        try {

            // Fetch expertise from local storage
            const expertise = (await this.localStorageService.getExpertise())?.toLowerCase() ?? '';

            // If no user is available, default to Hospitality
            if (!user) {
                return CompanyTypeEnum.HOSPITALITY.toLowerCase();
            }

            
            // Normalize the company type and expertise to lowercase
            let userCompanyType = user.type === 'employer' ? user.companyType.toLowerCase() : expertise;
            

            // Check if the user is related to Healthcare by company type,
            // stored expertise, or having healthcare-related skills
            const isHealthcare = user.type === 'employer' && userCompanyType.toLowerCase() === CompanyTypeEnum.HEALTHCARE.toLowerCase()
            || (user.type === 'staffer' && (
                userCompanyType.toLowerCase() === CompanyTypeEnum.HEALTHCARE.toLowerCase() 
                || (user.skills && await this.skillFilterationService.doesUserHasHCSkills(user.skills))
            ));
        
            // Return Healthcare or Hospitality based on the user's status
            return isHealthcare ? CompanyTypeEnum.HEALTHCARE.toLowerCase() : CompanyTypeEnum.HOSPITALITY.toLowerCase();
        }
        catch(error) {
            console.log('error while evaluating profession: ', error);
        }
    }

    /**
     * Sets the logo path based on the user's expertise.
     * If the user's expertise is Healthcare, the healthcare logo will be used.
     * Otherwise, the hospitality logo will be used as default.
     *
     * @param userData - user information to determine expertise.
     * @param selectedExpertise - Optional, if user data is not available (pre signup) then user selected expertise
     * @returns The path to the appropriate logo.
     */
    async setLogoBasedOnExpertise(userData?: IUser, selectedExpertise?: string) {
        // Fetch the user's expertise (either Healthcare or Hospitality)
        
        try {
            const expertise = selectedExpertise ? selectedExpertise : await this.fetchUserExpertise(userData);
            // Normalize the expertise to lowercase for comparison
    
            // Default logo path for Hospitality
            let logoPath = 'assets/images/hospitality-logo.png';
            let textLogo = 'assets/images/hospitality-text-logo.png';
            let logoIconPath = 'assets/images/staffy-hospitality-logo-icon.png';
            let logoOrangeIconPath = 'assets/images/staffy-hospitality-icon-orange.png';

            
            if(userData?.type === 'employer' && userData?.companyType == 'Hospitality') {
                // Default logo path for Hospitality
                logoPath = 'assets/images/hospitality-user-logo.png';
                textLogo = 'assets/images/hospitality-text-logo.png';
                logoIconPath = 'assets/images/staffy-user-hospitality-icon.png';
                logoOrangeIconPath = 'assets/images/staffy-hospitality-icon-orange.png';
            }

            // If the user's expertise is Healthcare, update the logo path to Healthcare logo
            if (expertise === CompanyTypeEnum.HEALTHCARE.toLowerCase()) {
                logoPath = 'assets/images/healthcare-logo.png';
                textLogo = "assets/images/healthcare-text-logo.png";
                logoIconPath = "assets/images/staffy-healthcare-logo-icon.png"
                logoOrangeIconPath = "assets/images/staffy-healthcare-icon-orange.png"

            }
    
            document.documentElement.style.setProperty('--staffy-logo', `url(${logoPath})`);
            document.documentElement.style.setProperty('--staffy-logo-icon', `url(${logoIconPath})`);
            document.documentElement.style.setProperty('--staffy-icon-orange', `url(${logoOrangeIconPath})`);
    
            await this.localStorageService.setStaffyLogoAndIcon(logoPath, textLogo, logoIconPath, logoOrangeIconPath);
        } catch(error) {
            console.log('error while setting expertise: ', error);
        }
    }   
}
<ion-tabs>
    <ion-tab-bar slot="bottom">
      <ion-tab-button [ngClass] = "{'activeTab' : activeTab == 'home'}" (click)="setRootTab('home')">
        <span>

          <ion-icon name="home"></ion-icon>
        </span>
      </ion-tab-button>
  
      <ion-tab-button  [ngClass] = "{'activeTab' : activeTab == 'upcoming-shifts'}" (click)="setRootTab('upcoming-shifts')">
        <span class = "upcoming-span">
          <i class="fas fa-tasks"></i>
          <div class="icon-wrapper upcoming" *ngIf = "upcomingShiftsCount > 0">
            <div class="circle">
              <span class="badge" *ngIf = "upcomingShiftsCount < 10">{{ upcomingShiftsCount }}</span>
              <span class="badge" *ngIf = "upcomingShiftsCount >= 10">10+</span>
            </div>
          </div>
        </span>
      </ion-tab-button>
  
      <ion-tab-button class = "notification" (click)="setRootTab('notifications')">
        <div *ngIf = "staffyLogo" class = "logo" [ngClass] = "{'hospitality-logo': staffyLogo && staffyLogo.includes('hospitality')}">
          <img [src] = "staffyLogo">
        </div>
        <div class="icon-wrapper" *ngIf = "notificationCount > 0" [ngClass] = "{'hospitality-notification': staffyLogo && staffyLogo.includes('hospitality')}">
          <i class="fa fa-bell">
            <span class="badge" *ngIf = "notificationCount < 9">{{ notificationCount }}</span>
            <span class="badge" *ngIf = "notificationCount >= 9">9+</span>
          </i>
        </div>
      </ion-tab-button>
  
      <ion-tab-button [ngClass] = "{'activeTab' : activeTab == 'active-shifts'}" (click)="setRootTab('active-shifts')">
        <ion-icon name="time" class = "time"></ion-icon>
        <span class = "upcoming-span">
          <div class="icon-wrapper upcoming" *ngIf = "shiftInProgress">
            <div class="circle">
              <span class="badge">1</span>
            </div>
          </div>
        </span>
      </ion-tab-button>
  
      <ion-tab-button [ngClass] = "{'activeTab' : activeTab == 'wallet'}" (click)="setRootTab('wallet')">
        <span>
          <ion-icon name="wallet"></ion-icon>
        </span>
      </ion-tab-button>
    </ion-tab-bar>
  </ion-tabs>
import { NgModule, ModuleWithProviders, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpService } from './services/http.service';
import { PopupService } from './services/popup.service';
import { IonicStorageModule } from '@ionic/storage';
import { LocalStorageService } from './services/local-storage.service';
import { LoadingService } from './services/loading.service';
import { AddressMapComponent } from './components/address-map/address-map.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChoosePictureComponent } from './components/choose-picture/choose-picture.component';
import { WebcamModule } from 'ngx-webcam';
import { TranslateModule } from '@ngx-translate/core';
import { HeaderComponent } from './components/header/header.component';
import { CountryPhoneNumbersComponent } from './components/country-phone-numbers/country-phone-numbers.component';
import { SuccessPopupComponent } from './components/success-popup/success-popup.component';
import { CompanyInfoScreenCalculationDirective } from './directives/company-info-screen-calculation.directive';
import { HttpPromiseService } from './services/http-promise.service';
import { SideMenuUtilService } from './services/sideMenu-util.service';
import { FriendReferralsListComponent } from './components/friend-referrals-list/friend-referrals-list.component';
import { AddSkillsRateFormComponent } from './components/add-skills-rate-form/add-skills-rate-form.component';
import { HomeStafferBadgesComponent } from './components/home-staffer-badges/home-staffer-badges.component';
import { RecurringShiftsTimingComponent } from './components/recurring-shifts-timing/recurring-shifts-timing.component';
import { NotesComponent } from './components/notes/notes.component';
import { StafferRatingPopupComponent } from './components/staffer-rating-popup/staffer-rating-popup.component';
import { RatingModule } from '@tahashahid/ng-starrating';
import { TimeTrackerHomeComponent } from './components/time-tracker-home/time-tracker-home.component';
import { MomentPipe } from './pipes/moment-format.pipe';
import { MomentPipeUnix } from './pipes/moment-format-unix.pipe';
import { ToggleComponent } from './components/toggle/toggle.component';
import { RemoveUnderscoreWithSpace } from './pipes/remover-underscore-with-space.pipe';
import { MissingRatingCardComponent } from './components/missing-rating-card/missing-rating-card.component';
import { SignalsIndicatorsComponent } from './components/signals-indicators/signals-indicators.component';
import { FormatTotalEarnings } from './pipes/format-earning.pipe';
import { FormatTimeTracked } from './pipes/time-tracked.pipe';
import { ChoosePictureModalComponent } from './components/choose-picture-modal/choose-picture-modal.component';
import { CovidQuizModalComponent } from '../talent-dashboard/components/covid-quiz-modal/covid-quiz-modal.component';
import { TalentCovidAssessmentService } from '../talent-dashboard/services/talent-covid-assessment.service';
import { MilisecondsToHumanizePipe } from './pipes/mili-second-to-humanize.pipe';
import { StafferWorkedForTheJob } from './pipes/staffer-worked-for-job.pipe';
import { ChooseTimeModalComponent } from './components/choose-time-modal/choose-time-modal.component';
import { BroadcastCardComponent } from './components/broadcast-card/broadcast-card.component';
import { DpDatePickerModule } from 'ng2-date-picker';
import { ShiftsPropertiesSignalsComponent } from '../talent-dashboard/components/shifts-properties-signals/shifts-properties-signals.component';
import { PhoneNumberTemplateComponent } from './components/phone-number-template/phone-number-template.component';
import { ClientInvoiceCardComponent } from './components/client-invoice-card/client-invoice-card.component';
import { DebounceClickDirective } from './directives/debounce-click.directive';
import { ViewCertificateModalComponent } from './components/view-certificate-modal/view-certificate-modal.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ReplaceLineBreaks } from './pipes/line-break.pipe';
import { RateAppModalComponent } from '../talent-dashboard/components/rate-app-modal/rate-app-modal.component';
import { AvailableShiftsCardsComponent } from '../talent-dashboard/components/available-shifts-cards/available-shifts-cards.component';
import { ShiftCardComponent } from '../talent-dashboard/components/shift-card/shift-card.component';
import { EditPartialUserInfoComponent } from './components/edit-partial-user-info/edit-partial-user-info.component';
import { PlacesAutocompleteComponent } from './components/places-autocomplete/places-autocomplete.component';
import { SharedRoutingModule } from './shared.routing.module';
import { StatPayComponent } from './components/stat-pay/stat-pay.component';
import { TrainingShiftComponent } from './components/training-shift/training-shift.component';
import { BottomBarComponent } from './components/bottom-bar/bottom-bar.component';
import { ContractorHeaderComponent } from './components/contractor-header/contractor-header.component';
import { AddressModificationService } from './services/address-modification.service';
import { DetailPopupComponent } from './components/detail-popup/detail-popup.component';
import { OnBoardingStepService } from './services/onboarding-step.service';
import { NoContextFoundComponent } from 'src/modules/shared/components/no-context-found/no-context-found.component';
import { SharedToggleBarComponent } from 'src/modules/shared/components/shared-toggle-bar/shared-toggle-bar.component';
import { FullDetailComponent } from 'src/modules/shared/components/full-detail/full-detail.component';
import { ViewCertificatesComponent } from 'src/modules/shared/components/view-certificates/view-certificates.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { InvoicesService } from 'src/modules/shared/services/invoices.service';
import { NotificationListComponent } from 'src/modules/shared/components/notification-list/notification-list.component';
import { NotificationCardComponent } from 'src/modules/shared/components/notification-card/notification-card.component';
import { NotificationCardRecurringComponent } from 'src/modules/shared/components/notification-card-recurring/notification-card-recurring.component';
import { AddResumeFormComponent } from '../onboarding-staffer/components/add-resume-form/add-resume-form.component';
import { UpcomingShiftCardComponent } from '../talent-dashboard/components/upcoming-shift-card/upcoming-shift-card.component';
import { AddReferenceFormComponent } from '../onboarding-staffer/components/add-reference-form/add-reference-form.component';
import { CertificateListComponent } from '../talent-dashboard/components/certificate-list/certificate-list.component';
import { SelectExperienceComponent } from '../onboarding-staffer/components/select-experience/select-experience.component';
import { NotificationPopupComponent } from './notification-popup/notification-popup.component';
import { NotificationBadgesService } from './services/notificationBadges.sevice';
import { SubSkillsHealthCareFormComponent } from './components/sub-skills-health-care-form/sub-skills-health-care-form.component';
import { SafePipe } from './pipes/safe-url.pipe';
import { SkillFilterationService } from './services/skill-filteration.service';
import { ExpiryDateComponent } from '../onboarding-staffer/components/expiry-date/expiry-date.component';
import { SocialSignupComponent } from '../onboarding-staffer/components/social-signup/social-signup.component';
import { ContractUpdateNoticeComponent } from './components/contract-update-notice/contract-update-notice.component';
import { AlertUpdateUnitNumberComponent } from './components/alert-update-unit-number/alert-update-unit-number.component';
import { EditUnitNumberComponent } from './components/edit-unit-number/edit-unit-number.component';
import { SearchableSelectComponent } from './components/searchable-select/searchable-select.component';
import { SearchableSelectModalComponent } from './components/searchable-select/searchable-select-modal/searchable-select-modal.component';
import { ShiftDetailsModalComponent } from '../talent-dashboard/components/shift-details-modal/shift-details-modal.component';
import { ShiftTasksComponent } from './components/shift-tasks/shift-tasks.component';
import { ShiftPatientComponent } from './components/shift-patient/shift-patient.component';
import { AddTaskComponent } from '../company-dashboard/pages/tasks/add-task/add-task.component';
import { SearchFilterPipe } from './pipes/search-filter.pipe';
import { VideoPlayerComponent } from './components/video-player/video-player.component';
import { SanitizeHtmlPipe } from './pipes/sanitize-html.pipe';
import { VerifyEmailPopupComponent } from './components/verify-email-popup/verify-email-popup.component';
import { CSVService } from './services/csv.service';
import { DataTableComponent } from './components/data-table/data-table.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { IsHtmlPipe } from './pipes/is-html-string.pipe';
import { DateSelectorComponent } from './components/date-selector/date-selector.component';
import { ModifyUnitNumberComponent } from './modify-unit-number/modify-unit-number.component';
import { BulletinsComponent } from './bulletins/bulletins.component';
import { CopyPopoverComponent } from './components/copy-popover/copy-popover.component';
import { ClientHeaderComponent } from './components/client-header/client-header.component';
import { UploadProfilePictureComponent } from './upload-profile-picture/upload-profile-picture.component';
import { ShiftTagComponent } from './components/shift-tag/shift-tag.component';
import { ShiftBasicInfoComponent } from './components/shift-basic-info/shift-basic-info.component';
import { ShiftHistoryDetailComponent } from './components/shift-history-detail/shift-history-detail.component';
import { PreviousHireAddToTeamComponent } from '../company-dashboard/components/previous-hire-add-to-team/previous-hire-add-to-team.component';
import { SliderComponent } from './components/slider/slider.component';
import { FilledRequestContractorListComponent } from './components/filled-request-contractor-list/filled-request-contractor-list.component';
import { ShiftHeaderComponent } from './components/shift-header/shift-header.component';
import { FormSelectComponent } from './components/form-select/form-select.component';
import { FormTextareaComponent } from './components/form-textarea/form-textarea.component';
import { FormInputComponent } from './components/form-input/form-input.component';
import { FormAddNewComponent } from './components/form-add-new/form-add-new.component';
import { FormCircularCheckboxComponent } from './components/form-circular-checkbox/form-circular-checkbox.component';
import { FormRadioButtonsComponent } from './components/form-radio-buttons/form-radio-buttons.component';
import { FormCheckboxComponent } from './components/form-checkbox/form-checkbox.component';
import { FormSubmitButtonComponent } from './components/form-submit-button/form-submit-button.component';
import { FormGooglePlacesInputComponent } from './components/form-google-places-input/form-google-places-input.component';
import { CancelRecurrentShiftsComponent } from './components/cancel-recurrent-shifts/cancel-recurrent-shifts.component';
import { RecurringShiftListComponent } from './components/recurring-shift-list/recurring-shift-list.component';
import { RecurringShiftListItemComponent } from './components/recurring-shift-list-item/recurring-shift-list-item.component';
import { NewPatientFormComponent } from './components/new-patient-form/new-patient-form.component';
import { FormErrorMessageComponent } from './components/form-error-message/form-error-message.component';
import { FormErrorsComponent } from './components/form-errors/form-errors.component';
import { FormDatePickerComponent } from './components/form-date-picker/form-date-picker.component';
import { FormSelectComponentV2 } from './components/form-select-v2/form-select-v2.component';
import { ShiftPatientV2Component } from './components/shift-patient-v2/shift-patient-v2.component';
import { EditRecurrentShiftModalComponent } from './components/edit-recurrent-shift-modal/edit-recurrent-shift-modal.component';
import { EditRecurrentShiftFooterComponent } from './components/edit-recurrent-shift-footer/edit-recurrent-shift-footer.component';
import { EditRecurrentSpecificShiftsComponent } from './components/edit-recurrent-specific-shifts/edit-recurrent-specific-shifts.component';
import { EditRecurringShiftListComponent } from './components/edit-recurring-shift-list/edit-recurring-shift-list.component';
import { EditRecurringShiftListItemComponent } from './components/edit-recurring-shift-list-item/edit-recurring-shift-list-item.component';
import { EditRecurrentFullRecurrenceComponent } from './components/edit-recurrent-full-recurrence/edit-recurrent-full-recurrence.component';
import { ContractorShiftModifiedComponent } from './components/contractor-shift-modified/contractor-shift-modified.component';
import { ContractorRecurrentShiftComponent } from './components/contractor-recurrent-shift/contractor-recurrent-shift.component';
import { CertificateDatePickerComponent } from './components/certificate-date-picker/certificate-date-picker.component';
import { AnalyticsCardComponent } from './components/analytics-card/analytics-card.component';
import { ShiftDetailContainerComponent } from './components/shift-detail-container/shift-detail-container.component';
import { ClientFilterPipe } from './pipes/client-filter.pipe';
import { RecurrentShiftListComponent } from './components/recurrent-shift-list/recurrent-shift-list.component';
import { RecurrentShiftListItemComponent } from './components/recurrent-shift-list-item/recurrent-shift-list-item.component';
import { AddressMapComponentV2 } from './components/address-map-v2/address-map-v2.component';
import { CalendarEventsComponent } from './components/calendar-events/calendar-events.component';
import { ContractorImageCardComponent } from './components/contractor-image-card/contractor-image-card.component';
import { SinglePatientShiftSelectionComponent } from './components/single-patient-shift-selection/single-patient-shift-selection.component';
import { MultiplePatientShiftSelectionComponent } from './components/multiple-patient-shift-selection/multiple-patient-shift-selection.component';
import { ShiftMultiAddressListComponent } from './components/shift-multi-address-list/shift-multi-address-list.component';
import { ShiftCarenotesComponent } from './components/shift-carenotes/shift-carenotes.component';
import { MultiAddressDetailComponent } from './components/multi-address-detail/multi-address-detail.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { PdfViewerComponent } from './components/pdf-viewer/pdf-viewer.component';
import { BulletinViewerComponent } from './components/bulletin-viewer/bulletin-viewer.component';
import { ShiftAttendanceInfoComponent } from './components/shift-attendance-info/shift-attendance-info.component';
import { EmbedDOCFilePipe } from './pipes/embed-doc-file.pipe';
import { ServiceFeeUpdateNoticeComponent } from './components/service-fee-update-notice/service-fee-update-notice.component';
import { DownloadService } from './services/download.service';

@NgModule({
  imports: [
    IonicStorageModule.forRoot({
      dbKey: '',
      driverOrder: ['sqlite', 'indexeddb', 'websql']
    }),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    DpDatePickerModule,
    WebcamModule,
    TranslateModule,
    RatingModule,
    NgxDocViewerModule,
    PdfViewerModule,
    SharedRoutingModule,
    CommonModule,
    NgCircleProgressModule.forRoot({}),
    NgxDatatableModule,
  ],


  declarations: [
    AddressMapComponent,
    AddressMapComponentV2,
    AddSkillsRateFormComponent,
    FriendReferralsListComponent,
    MissingRatingCardComponent,
    ChoosePictureComponent,
    HeaderComponent,
    ClientHeaderComponent,
    BottomBarComponent,
    ContractorHeaderComponent,
    ShiftsPropertiesSignalsComponent,
    CountryPhoneNumbersComponent,
    SuccessPopupComponent,
    DetailPopupComponent,
    UpcomingShiftCardComponent,
    AddReferenceFormComponent,
    PhoneNumberTemplateComponent,
    ChoosePictureModalComponent,
    NotificationListComponent,
    NotificationCardComponent,
    AddResumeFormComponent,
    NotificationCardRecurringComponent,
    ChooseTimeModalComponent,
    StafferRatingPopupComponent,
    CompanyInfoScreenCalculationDirective,
    DebounceClickDirective,
    HomeStafferBadgesComponent,
    RecurringShiftsTimingComponent,
    NotesComponent,
    SocialSignupComponent,
    DateSelectorComponent,
    TimeTrackerHomeComponent,
    BroadcastCardComponent,
    ToggleComponent,
    MomentPipe,
    MomentPipeUnix,
    MilisecondsToHumanizePipe,
    StafferWorkedForTheJob,
    FormatTimeTracked,
    FormatTotalEarnings,
    ReplaceLineBreaks,
    RemoveUnderscoreWithSpace,
    SignalsIndicatorsComponent,
    CovidQuizModalComponent,
    PreviousHireAddToTeamComponent,
    ClientInvoiceCardComponent,
    ViewCertificateModalComponent,
    EditUnitNumberComponent,
    RateAppModalComponent,
    AvailableShiftsCardsComponent,
    ShiftCardComponent,
    EditPartialUserInfoComponent,
    PlacesAutocompleteComponent,
    StatPayComponent,
    SubSkillsHealthCareFormComponent,
    SafePipe,
    TrainingShiftComponent,
    NoContextFoundComponent,
    SharedToggleBarComponent,
    FullDetailComponent,
    ViewCertificatesComponent,
    CertificateListComponent,
    SelectExperienceComponent,
    NotificationPopupComponent,
    ExpiryDateComponent,
    ContractUpdateNoticeComponent,
    AlertUpdateUnitNumberComponent,
    EditUnitNumberComponent,
    SearchableSelectComponent,
    SearchableSelectModalComponent,
    ShiftDetailsModalComponent,
    ShiftTasksComponent,
    ShiftPatientComponent,
    AddTaskComponent,
    SearchFilterPipe,
    VideoPlayerComponent,
    PdfViewerComponent,
    SanitizeHtmlPipe,
    VerifyEmailPopupComponent,
    DataTableComponent,
    ModifyUnitNumberComponent,
    IsHtmlPipe,
    ModifyUnitNumberComponent,
    BulletinsComponent,
    ShiftTagComponent,
    ShiftBasicInfoComponent,
    ShiftDetailsModalComponent,
    PaginationComponent,
    ShiftHistoryDetailComponent,
    BulletinsComponent,
    AnalyticsCardComponent,
    ShiftHeaderComponent,
    FormSelectComponent,
    FormTextareaComponent,
    FormInputComponent,
    FormAddNewComponent,
    FormCircularCheckboxComponent,
    FormRadioButtonsComponent,
    FormCheckboxComponent,
    FormSubmitButtonComponent,
    FormGooglePlacesInputComponent,
    FormErrorMessageComponent,
    FormDatePickerComponent,
    CopyPopoverComponent,
    CancelRecurrentShiftsComponent,
    RecurringShiftListComponent,
    RecurringShiftListItemComponent,
    UploadProfilePictureComponent,
    FormGooglePlacesInputComponent,
    NewPatientFormComponent,
    FormErrorsComponent,
    FormErrorMessageComponent,
    FormDatePickerComponent,
    CopyPopoverComponent,
    UploadProfilePictureComponent,
    SliderComponent,
    FilledRequestContractorListComponent,
    FormSelectComponentV2,
    ShiftPatientV2Component,
    EditRecurrentShiftModalComponent,
    EditRecurrentShiftFooterComponent,
    EditRecurrentSpecificShiftsComponent,
    EditRecurringShiftListComponent,
    EditRecurringShiftListItemComponent,
    EditRecurrentFullRecurrenceComponent,
    ContractorShiftModifiedComponent,
    ContractorRecurrentShiftComponent,
    CertificateDatePickerComponent,
    ShiftDetailContainerComponent,
    ClientFilterPipe,
    ContractorShiftModifiedComponent,
    RecurrentShiftListComponent,
    RecurrentShiftListItemComponent,
    CalendarEventsComponent,
    ContractorImageCardComponent,
    SinglePatientShiftSelectionComponent,
    MultiplePatientShiftSelectionComponent,
    ShiftMultiAddressListComponent,
    ShiftCarenotesComponent,
    MultiAddressDetailComponent,
    EditRecurrentFullRecurrenceComponent,
    BulletinViewerComponent,
    ShiftAttendanceInfoComponent,
    EmbedDOCFilePipe,
    ServiceFeeUpdateNoticeComponent
  ],

    
  exports: [
    SearchableSelectComponent,
    AddressMapComponent,
    AddressMapComponentV2,
    BroadcastCardComponent,
    AddSkillsRateFormComponent,
    HomeStafferBadgesComponent,
    ShiftsPropertiesSignalsComponent,
    FriendReferralsListComponent,
    MissingRatingCardComponent,
    ChoosePictureComponent,
    HeaderComponent,
    PdfViewerComponent,
    ClientHeaderComponent,
    BottomBarComponent,
    ContractorHeaderComponent,
    CountryPhoneNumbersComponent,
    RecurringShiftsTimingComponent,
    NotesComponent,
    SocialSignupComponent,
    DateSelectorComponent,
    SuccessPopupComponent,
    DetailPopupComponent,
    AddReferenceFormComponent,
    UpcomingShiftCardComponent,
    PhoneNumberTemplateComponent,
    ChoosePictureModalComponent,
    NotificationListComponent,
    NotificationCardComponent,
    AddResumeFormComponent,
    NotificationCardRecurringComponent,
    ChooseTimeModalComponent,
    StafferRatingPopupComponent,
    CompanyInfoScreenCalculationDirective,
    DebounceClickDirective,
    TimeTrackerHomeComponent,
    ToggleComponent,
    MomentPipe,
    MomentPipeUnix,
    MilisecondsToHumanizePipe,
    StafferWorkedForTheJob,
    FormatTimeTracked,
    RemoveUnderscoreWithSpace,
    FormatTotalEarnings,
    ReplaceLineBreaks,
    SignalsIndicatorsComponent,
    ClientInvoiceCardComponent,
    ViewCertificateModalComponent,
    DpDatePickerModule,
    RateAppModalComponent,
    EditUnitNumberComponent,
    AvailableShiftsCardsComponent,
    ShiftCardComponent,
    EditPartialUserInfoComponent,
    StatPayComponent,
    SubSkillsHealthCareFormComponent,
    SafePipe,
    TrainingShiftComponent,
    NoContextFoundComponent,
    SharedToggleBarComponent,
    FullDetailComponent,
    ViewCertificatesComponent,
    CertificateListComponent,
    SelectExperienceComponent,
    NotificationPopupComponent,
    ExpiryDateComponent,
    ContractUpdateNoticeComponent,
    AlertUpdateUnitNumberComponent,
    EditUnitNumberComponent,
    ShiftDetailsModalComponent,
    ShiftTasksComponent,
    ShiftPatientComponent,
    PlacesAutocompleteComponent,
    AddTaskComponent,
    SearchFilterPipe,
    VideoPlayerComponent,
    SanitizeHtmlPipe,
    VerifyEmailPopupComponent,
    DataTableComponent,
    ModifyUnitNumberComponent,
    IsHtmlPipe,
    ModifyUnitNumberComponent,
    BulletinsComponent,
    ShiftTagComponent,
    ShiftBasicInfoComponent,
    ShiftDetailsModalComponent,
    PaginationComponent,
    ShiftHistoryDetailComponent,
    AnalyticsCardComponent,
    CopyPopoverComponent,
    UploadProfilePictureComponent,
    SliderComponent,
    FilledRequestContractorListComponent,
    BulletinsComponent,
    AnalyticsCardComponent,
    ShiftHeaderComponent,
    FormSelectComponent,
    FormTextareaComponent,
    FormInputComponent,
    FormAddNewComponent,
    FormCircularCheckboxComponent,
    FormRadioButtonsComponent,
    FormCheckboxComponent,
    FormSubmitButtonComponent,
    UploadProfilePictureComponent,
    FormGooglePlacesInputComponent,
    NewPatientFormComponent,
    FormErrorsComponent,
    FormErrorMessageComponent,
    FormDatePickerComponent,
    AnalyticsCardComponent,
    CopyPopoverComponent,
    FormSelectComponentV2,
    ShiftPatientV2Component,
    EditRecurrentShiftModalComponent,
    EditRecurringShiftListComponent,
    EditRecurringShiftListItemComponent,
    ContractorShiftModifiedComponent,
    ContractorRecurrentShiftComponent,
    CertificateDatePickerComponent,
    ShiftDetailContainerComponent,
    ClientFilterPipe,
    ContractorShiftModifiedComponent,
    RecurrentShiftListComponent,
    RecurrentShiftListItemComponent,
    CalendarEventsComponent,
    ContractorImageCardComponent,
    SinglePatientShiftSelectionComponent,
    MultiplePatientShiftSelectionComponent,
    ShiftMultiAddressListComponent,
    MultiAddressDetailComponent,
    BulletinViewerComponent,
    ShiftAttendanceInfoComponent,
    EmbedDOCFilePipe,
    ServiceFeeUpdateNoticeComponent
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        HttpService,
        HttpPromiseService,
        PopupService,
        LocalStorageService,
        LoadingService,
        SideMenuUtilService,
        TalentCovidAssessmentService,
        AddressModificationService,
        SkillFilterationService,
        OnBoardingStepService,
        InvoicesService,
        NotificationBadgesService,
        CSVService,
        DownloadService,
      ]
    };
  }
}

import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { IUser } from 'src/modules/authentication/interfaces/user.interface';
import { ITalentJob } from 'src/modules/talent-dashboard/interfaces/talent-job.interface';
import { ISetting } from 'src/modules/talent-dashboard/interfaces/settings.interface';
import { IFAQ } from '../pages/faqs/interfaces/faq.interface';


@Injectable()
export class LocalStorageService {

  constructor(private storage: Storage) { }

  setLoggedInUserToken(accessToken: string) {
    this.storage.set('token', accessToken);
  }
  
  async getLanguage() {
    try {
      return await this.storage.get('language') || null;
    } catch (e) {
      console.log(e);
    }
  }

  setBio(bio: string) {
    this.storage.set('bio', bio);

  }

  async getBio() {
    try {
      return await this.storage.get('bio') || null;
    } catch (e) {
      console.log(e);
    }
  }

  setLanguage(lng: string) {
    this.storage.set('language', lng);

  }

  async getExpertise() {
    try {
      return this.storage.get('expertise') || null;
    } catch (e) {
      console.log(e);
    }
  }

  setExpertise(expertise: string) {
    this.storage.set('expertise', expertise);
  }


  async getApparel() {
    try {
      return await this.storage.get('apparel') || null;
    } catch (e) {
      console.log(e);
    }
  }

  setApparel(apparel) {
    this.storage.set('apparel', apparel);
  }

  setLoggedInUserInfo(user: IUser) {
    this.storage.set('user', user);
  }

  async removeLoggedInUserInfo() {
    return await this.storage.get('user') || null;
  }

  storeUserSetting(setting: ISetting) {
    this.storage.set('setting', setting);
  }

  setRemoveReferralBannerPopup(hideReferralBanner: Boolean){
    this.storage.set('hideReferralBanner', hideReferralBanner)
  }

  async getRemoveReferralBannerPopup(){
    try{
      return !!await this.storage.get('hideReferralBanner');
    } catch(e) {
      console.log(e)
    }
  }

  setShiftInProgress(shiftInProgress: boolean){
    this.storage.set('shiftInProgress', shiftInProgress)
  }

  async getShiftInProgress(){
    try{
      return !!await this.storage.get('shiftInProgress');
    } catch(e) {
      console.log(e)
    }
  }

  setLocalCount(localCount: number){
    this.storage.set('localCount', localCount)
  }

  async getLocalCount(){
    try{
      return !!await this.storage.get('localCount');
    } catch(e) {
      console.log(e)
    }
  }

  async getSetiings() {
    try {
      return await this.storage.get('setting') || null;
    } catch (e) {
      console.log(e);
    }
  }

  setUserTimeZoneAndCity(timezone: string) {
    this.storage.set('city', timezone);
    this.storage.set('timezone', timezone);
  }

  setTalentUpcomingShifts(upcomingShifts: ITalentJob[]) {
    this.storage.set('upcomingShifts', upcomingShifts);
  }

  setCompletionStatusOfOnboarding(onboardingStep) {
    this.storage.set('onboardingStep', onboardingStep);
  }

  async getCompletionStatusOfOnboarding() {
    try {
      return await this.storage.get('onboardingStep') || null;
 
    } catch (e) {
      console.log(e);
    }
  }

  setSignupCode(signupCode: string) {
    this.storage.set('signupCode', signupCode);
  }
  async getSignupCode() {
    try {
      return await this.storage.get('signupCode') || null;
    } catch (e) {
      console.log(e);
    }
  }
  async getLoggedInUserToken() {
    try {
      return await this.storage.get('token') || null;
    } catch (e) {
      console.log(e);
    }
  }

  async getLoggedInUserInfo() {
    try {
      return await this.storage.get('user') || null;
    } catch (e) {
      console.log(e);
    }
  }

  async getUserTimeZone() {
    try {
      return await this.storage.get('timezone') || 'America/Toronto';
    } catch (e) {
      console.log(e);
    }
  }

  async getTalentUpcomingShifts() {
    try {
      return await this.storage.get('upcomingShifts') || [];
    } catch (e) {
      console.log(e);
    }
  }

  async getVoucherCode() {
    try {
      return await this.storage.get('voucher') || null;
    } catch (e) {
      console.log(e);
    }
  }

  async removeSingleKey(key: string) {
    try {
      return await this.storage.remove(key);
    } catch (e) {
      console.log(e);
    }
  }

  async getSingleKey(key: string) {
    try {
      return await this.storage.get(key) || null;
    } catch (e) {
      console.log(e);
    }
  }
  setSingleKey(key: string, value: any) {
    this.storage.set(key, value);
  }

  setFAQs(faqs: IFAQ[]) {
    this.storage.set('faqs', faqs);

  }
  async getFAQs() {
    try {
      return await this.storage.get('faqs') || [];
    } catch (e) {
      console.log(e);
    }
  }

  async setStaffyLogoAndIcon(staffyLogo: string, staffyTextLogo: string, staffyIcon: string, staffyIconOrange: string) {
    try {
      // Set both logo and icon as separate entries in local storage
      await this.storage.set('staffyLogo', staffyLogo);
      await this.storage.set('staffyIcon', staffyIcon);
      await this.storage.set('staffyTextLogo', staffyTextLogo);
      await this.storage.set('staffyIconOrange', staffyIconOrange);
    } catch (e) {
      console.log(e);
    }
  }

  async getStaffyLogo(){
    try {
      return await this.storage.get('staffyLogo'); 
    } catch (e) {
      console.log(e);
      return;
    }
  }

  async getStaffyTextLogo() {
    try {
      return await this.storage.get('staffyTextLogo'); // Retrieve text icon
    }
    catch(e) {
      console.log(e);
    }
  }

  async getStaffyIcon(color?: string){
    try {
      if(color === 'orange') {
        return await this.storage.get('staffyIconOrange'); // Retrieve icon
      }
      return await this.storage.get('staffyIcon')
    } catch (e) {
      console.log(e);
      return
    }
  }

  resetLogoStyles() {
    // Reset the logo-related CSS variables to their default values (if any)
    document.documentElement.style.removeProperty('--staffy-logo');
    document.documentElement.style.removeProperty('--staffy-logo-icon');
    document.documentElement.style.removeProperty('--staffy-icon-orange');
  }

  async logout() {
    try {
      console.log(this.storage);
      await this.storage.clear();
      await localStorage.setItem("filterOutClients", JSON.stringify([]));
      // Call the function to reset logo-related CSS variables
      this.resetLogoStyles();
    } catch (e) {
      console.log(e);
    }
  }
}

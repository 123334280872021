import { Injectable } from '@angular/core';
import { HttpPromiseService } from 'src/modules/shared/services/http-promise.service';
import { IUser } from 'src/modules/authentication/interfaces/user.interface';
import { IStafferInvoice } from 'src/modules/onboarding-staffer/interfaces/invoice.interface';
import { HttpService } from 'src/modules/shared/services/http.service';



@Injectable({ providedIn: 'root' })

export class TalentDashboardService {

  constructor(
    private httpPromiseService: HttpPromiseService,
    private http: HttpService,
  ) { }



  public acceptBroadcastRequestRecurring(groupJobId: string, stafferId: string, acceptedRcmpFee: boolean) {
    return this.httpPromiseService.httpPutRequest(
      `/api/recurringShifts/${groupJobId}/acceptBroadcastRequest?acceptedRcmpFee=${acceptedRcmpFee}`,
      { stafferId }
    );
  }



  public updateStartTime(startTime: number, trackerId: string) {
    return this.httpPromiseService.httpPutRequest(
      `/api/tracker/updateStartTime`,
      {
        startTime,
        trackerId
      }
    );
  }
  public getAllReferral(toSkipItems: number, pageSize: number) {
    return this.httpPromiseService.httpGetRequest(
      `/api/refferals/?toSkipItems=${toSkipItems}&pageSize=${pageSize}`,
    );
  }

  public startTimer(jobId: string, startTime: number, user: IUser, location: {latitude: string, longitude: string}) {
    let city = user.address.city;
    city = user.address.province === 'Texas' ? 'Chicago' : city;

    return this.httpPromiseService.httpPostRequest(
      `/api/tracker/start`,
      {
        jobId,
        userId: user._id,
        startTime,
        timeZone: 'America/' + city,
        location,
      }
    );
  }

  public updateBreaks(breaks: unknown, trackerId: string, state: string) {

    return this.httpPromiseService.httpPutRequest(
      `/api/tracker/updateBreaks`,
      {
        breaks,
        trackerId,
        state
      }
    );
  }

  public stopTimer(jobId: string, stopTime: number, userId: string, location: {latitude: string, longitude: string}, startTime?: number, hasAddedFromStaffer?: boolean, forceSkipGeoCheck?: boolean) {

    return this.httpPromiseService.httpPostRequest(
      `/api/tracker/stop`,
      {
        jobId,
        userId,
        stopTime,
        startTime,
        hasAddedFromStaffer,
        location,
        forceSkipGeoCheck
      }
    );
  }



  public getSettings() {
    return this.httpPromiseService.httpGetRequest(
      `/api/settings `
    );
  }

  public getSettingsWithMaxSupportedStafferContractVersion(maxSupportedStafferContractVersion) {
    return this.httpPromiseService.httpGetRequest(
      `/api/settings/maxSupportedStafferContractVersion/${maxSupportedStafferContractVersion}`
    );
  }

  public getNotifications(userId: string) {
    return this.httpPromiseService.httpGetRequest(
      `/api/notifications/myNotificationsUpdated/${userId} `
    );
  } 
  
  public getSingleNotifications(notificationId: string) {
    return this.httpPromiseService.httpGetRequest(
      `/api/notifications/${notificationId} `
    );
  }

  public dismissOldNotifications(userId: string, userTimeNow: number) {
    return this.httpPromiseService.httpPostRequest(
      `/api/notifications/${userId}/dismissOldShiftNotifications`, { userTimeNow }
    );
  }

  public updateNotificationReadStatus(notificationId) {
    return this.httpPromiseService.httpPatchRequest(
      `/api/notifications/${notificationId} `, { read: true }
    );
  }

  public updateViewStatusForAll() {
    return this.httpPromiseService.httpPutRequest(
      `/api/notifications/updateViewStatusForAll`, {}
    );
  }






  public acceptRecurringHireRequest(body: {
    stafferId: string,
    groupJobId: string,
    shiftResponseTime: number
  }) {
    return this.httpPromiseService.httpPutRequest(
      `/api/recurringShifts/shift/acceptShiftRequest`, body
    );
  }


  public rejectRecurringHireRequest(body: {
    stafferId: string
    groupJobId: string,
  }) {
    return this.httpPromiseService.httpPutRequest(
      `/api/recurringShifts/shift/rejectShiftRequest`, body
    );
  }

  public stillWorkingStafferNonRecurringJob(trackerId: string, ) {
    return this.httpPromiseService.httpPostRequest(
      `/api/tracker/tracker/updateTracker/stafferStillWorking`, { trackerId }
    );
  }

  public addAsTeam(userId: string, body: {
    code: string
  }) {
    return this.httpPromiseService.httpPutRequest(
      `/api/teams/addUserInATeam/${userId}`, body
    );
  }
  public generateYearlyReport(userId: string, body: { year: string }) {
    return this.httpPromiseService.httpPatchRequest(
      `/api/invoicesNewModel/generateYearlyReport/${userId}`, body
    );
  }

  public getInvoices(userId: string, filter, toSkipItems: number, pageSize: number) {
    return this.httpPromiseService.httpPatchRequest(
      `/api/invoicesNewModel?type=staffer&stafferId=${userId}&isGeneratedByQuery=false&invoiceId=undefined&filterByStatus=${filter}&toSkipItems=${toSkipItems}&pageSize=${pageSize}`, {},
    );
  }

  public getWalletTransactions(sortOptions: {
    sortColumn: string,
    sortOrder: string
  } = {
    sortColumn: 'created_date',
    sortOrder: 'desc'
    }, toSkipItems: number, pageSize: number) {
    return this.httpPromiseService.httpGetRequest(
      `/api/walletPayments?sortColumn=${sortOptions.sortColumn}&sortOrder=${sortOptions.sortOrder}&&skip=${toSkipItems}&page=${pageSize}`,
    );
  }

  public downloadWalletTransactions(exportOption: string) {
    return this.httpPromiseService.httpPostRequest(
      `/api/walletPayments/download`, {
        exportOption : parseInt(exportOption)
      }
    );
  }

  public getWalletStatistics() {
    return this.httpPromiseService.httpGetRequest(
      `/api/walletPayments/stats` ,
    );
  }

  public withDrawAmountFromWallet(body: {
    amount: number
  }) {
    return this.httpPromiseService.httpPostRequest(
      `/api/walletPayments/withdraw`, body
    );
  }

  public getTransactionCardDetails(userId: string, sortBy: string) {
    return this.httpPromiseService.httpGetRequest(
      `/api/invoicesNewModel/transactionCard/${userId}?sortBy=${sortBy}`);
  }

  public generateInvoicePdf(invoice: IStafferInvoice) {
    return this.httpPromiseService.httpPostRequest(
      `/api/invoicesNewModel/invoice/sendInvoiceAsPDF`, { invoice },
    );
  }

  public payStaffy(stafferId: string, invoiceId: string) {
    return this.httpPromiseService.httpPostRequest(
      `/api/invoicesNewModel/contractorInvoice/staffyPayImmediate/payNow/${stafferId}/${invoiceId}`, {},
    );
  }

  public updateInvoice(invoice: IStafferInvoice) {
    return this.httpPromiseService.httpPatchRequest(
      `/api/invoicesNewModel/updateInvoice/${invoice._id}`, { invoice },
    );
  }

  public fetchSingleInvoice(jobId: string, userId: string) {
    return this.httpPromiseService.httpGetRequest(
      `/api/invoicesNewModel/singleInvoiceDetail/${jobId}/${userId}`
    );
  }

  public createStafferWaiver(data: {
    staffer: string,
    documentViewed: {
      time: string
    }
  }) {
    return this.httpPromiseService.httpPostRequest(
      `/api/stafferWaiver`, data,
    );
  }

  public submitWaiver(stafferId: string, data: {
    documentSignedTime: string,
    documentCompletedTime: string,
    dateOfBirth: string
  }) {
    return this.httpPromiseService.httpPutRequest(
      `/api/stafferWaiver/submit/${stafferId}`,
      data,
    );
  }

  public getStafferWaiver(
    stafferId: string,
  ) {
    return this.httpPromiseService.httpGetRequest(
      `/api/stafferWaiver/${stafferId}`,
    );
  }

  public createStafferAttestation(data: {
    staffer: string,
    documentViewed: {
      time: string
    }
  }) {
    return this.httpPromiseService.httpPostRequest(
      `/api/vssAndImmunizationAttestation`, data,
    );
  }

  public getStafferAttestation(
    stafferId: string,
  ) {
    return this.httpPromiseService.httpGetRequest(
      `/api/vssAndImmunizationAttestation/${stafferId}`,
    );
  }

  public submitAttestation(userId: string, data, documentSignedTime: string, documentCompletedTime: string) {
        return this.httpPromiseService.httpPostRequest(
            `/api/vssAndImmunizationAttestation/${userId}`, {data, documentSignedTime, documentCompletedTime}
        );
    }

    public checkForUnverifiedHours(userId: string){
      return this.httpPromiseService.httpGetRequest(
        `/api/tracker/unconfirmed?contractorId=${userId}`
      )
    }

    public getRequestOfSubSkill(userId: string) {
      return this.httpPromiseService.httpGetRequest(
        `/api/pendingSubSkills/getAllRequestsByStafferId/${userId}`);
    }

  public createRequestForSubSkill(userId: string, data) {
    return this.httpPromiseService.httpPutRequest(
      `/api/pendingSubSkills/createRequest/${userId}`,
      data
    );
  }

  public getUserSubSkillRequest(userId: string) {
    return this.httpPromiseService.httpGetRequest(
      `/api/pendingSubSkills/getAllRequestsByStafferId/${userId}?pendingOnly=true`
    );
  }

  public removeSubSkillRequest(userId: string) {
    return this.httpPromiseService.httpDeleteRequest(
      `/api/pendingSubSkills/deleteRequest/${userId}`);
  }

  public completeTaskRequest(jobId: string, taskId: string,patientId: string) {
    return this.http.httpPutRequest(
      `/api/v2/jobs/${jobId}/tasks/complete`,
      { patientId, taskId }
    );
  }

  public saveCareNotesRequest(jobId: string, careNotes: string,patientId : string) {
    return this.http.httpPutRequest(
      `/api/v2/jobs/${jobId}/care-notes`,
      { careNotes, patientId }
    );
  }

}

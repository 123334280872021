import { Component, OnInit } from '@angular/core';
// import { map, filter, pluck } from 'rxjs/operators';
import { Platform } from '@ionic/angular';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { getUserInfoAction, logOutAction } from 'src/modules/authentication/+store/actions/auth.actions';
import { Store, select } from '@ngrx/store';
import { IAuthState } from 'src/modules/authentication/+store/auth.state';
import { getUserInfo} from 'src/modules/authentication/+store/auth-selector';
import { take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { NetworkService } from 'src/modules/shared/services/network.service';
import { LanguageService } from 'src/modules/shared/services/language.service';
import { PushNotificationService } from 'src/modules/shared/services/push-notification.service';
import { IUserAddress } from 'src/modules/authentication/interfaces/user-address.interface';
import { LocalStorageService } from 'src/modules/shared/services/local-storage.service';
import { AppInfoService } from 'src/modules/shared/services/app-info.service';
import { UtilService } from 'src/modules/shared/services/util.services';
import { Subscription } from 'rxjs';
import { AppUpdateService } from './services/app-updates.service';
import { SkillsService } from 'src/modules/shared/services/skills.service';
import { IUser } from 'src/modules/authentication/interfaces/user.interface';
import { ConfigService } from './services/config.service';
import { StaffyRebrandingService } from 'src/modules/shared/services/staffy-rebranding.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  private apiUrlSubscription: Subscription;
  private stripeKeySubscription: Subscription;
  public showDemoBanner = true;
  ////////////////////////////// will be use for side menu ///////////
  public appPages = [];
  subscription: Subscription;
  showBackBtn: boolean;
  userType$: Observable<string>;
  userType: string;
  userWallet$: Observable<number>;
  userApproved$: Observable<boolean>;
  userEnableDH$: Observable<boolean>;
  enableManualHoursOnApp$: Observable<boolean>;
  userAddress$: Observable<IUserAddress>;
  userData$: Observable<IUser>;
  userData: IUser;
  userSkill$: Observable<any>;
  userApproved: boolean;
  userEnableDH: boolean;
  enableManualHoursOnApp: boolean;
  userAddress: IUserAddress;
  userSkills: [];
  city: string;
  userWallet: number;
  isOnline = true;
  isDemo = false;
  isHCW;
  notificationCount;
  userFirstName$: Observable<string>;
  userFirstName: string;
  userLastName$: Observable<string>;
  userLastName: string;

  userTotalEarned$: Observable<number>;
  userTotalEarned;

  userAggregatedRating$: Observable<number>;
  userAggregatedRating: number;

  userProfileUrl$: Observable<any>;
  userProfileUrl: any;

  userVerified$: Observable<boolean>;
  userVerified: boolean;

  userBadges$: Observable<any>;
  userBadges: any;
  activeMenu;
  shiftInProgress;

  readonly user = this.store.pipe(select(getUserInfo));

  // splash: boolean = true;
  ////////////////////////////////////
  constructor(
    private platform: Platform,
    private languageService: LanguageService,
    private store: Store<IAuthState>,
    private statusBar: StatusBar,
    private networkService: NetworkService,
    private pushNotificationService: PushNotificationService,
    private localStorageService: LocalStorageService,
    private appInfoService: AppInfoService,
    private utilService: UtilService,
    private appUpdates: AppUpdateService,
    private skillsService: SkillsService,
    private configService: ConfigService,
    private staffyRebrandingService: StaffyRebrandingService
  ) {
    if (~window.location.href.indexOf('app.staffy.com')) {
      // override console on prod
      const console = (oldCons => {
        return {
          log: text => {},
          info: text => {},
          warn: text => {},
          error: text => {}
        };
      })(window.console);
      (window as any).console = console;
    }

    this.initializeApp();
  }

  async ngOnInit() {}

  initializeApp() {
    this.platform.ready().then(async () => {
      this.subscription = this.user.subscribe(async (result) => {
        this.userData = result;
      });
      // this.mobileAccessibility.usePreferredTextZoom(false),
      this.statusBar.styleDefault();
      this.statusBar.overlaysWebView(false);
      this.statusBar.backgroundColorByHexString('#FF4D00');
      this.statusBar.styleLightContent();

      this.networkService.watchForNetwork();
      this.reloadPushWush();
      this.getAppVersionInfo();
      // this.splash = false;
      // this.splashScreen.hide();

      this.languageService.setInitialAppLanguage();

      this.isDemo = this.configService.isDemoMode();
      
      this.apiUrlSubscription = this.configService.apiUrl$.subscribe(newApiUrl => {
        console.log('API Base URL changed:', newApiUrl);
        // Handle the new API Base URL as needed
        this.isDemo = this.configService.isDemoMode();
        if (this.isDemo) this.showDemoBanner = true;
      });

      this.stripeKeySubscription = this.configService.stripeKey$.subscribe(newStripeKey => {
        console.log('Stripe Key changed:', newStripeKey);
      });

      this.networkService.appNetworkStatus.subscribe(status => {
        if (status === 'online') {
          this.isOnline = true;
        } else {
          this.isOnline = false;
        }
      });

      this.setParametersBasedOnExpertise();
      this.setDefaultTimeZone();

      // check for app updates
      this.appUpdates.check();
      // check for app store updates
      this.appInfoService.ensureUserHasMinimumRequiredVersion();
    });

    this.platform.resume.subscribe(async () => {
      // check for app updates
      this.appUpdates.check();

      //load user data
      // check for app store updates
      this.appInfoService.ensureUserHasMinimumRequiredVersion();

      // fetch update user from backend
      const user = await this.user.pipe(take(1)).toPromise();

      if(user && user._id){
        this.store.dispatch(getUserInfoAction());
        this.setParametersBasedOnExpertise();
      }
    });
  }

  async setParametersBasedOnExpertise() {
    const user = await this.localStorageService.getLoggedInUserInfo();
    await this.staffyRebrandingService.setLogoBasedOnExpertise(user);

  }

  async reloadPushWush() {
    const user = await this.localStorageService.getLoggedInUserInfo();
    this.pushNotificationService.initPushNotification(user);
  }

  async getAppVersionInfo() {
    const user = await this.localStorageService.getLoggedInUserInfo();
    if (user) {
      this.appInfoService.checkForAppInfo(user);
    }
  }

  async setDefaultTimeZone() {
    const user = await this.localStorageService.getLoggedInUserInfo();
    if (user) {
      this.utilService.setTimeZone(user.address.province);
    }
  }

  async logout() {
    this.configService.revertToDefault();
    await this.pushNotificationService.removePushNotificationToken();
    this.utilService.clearCashedJobs.next('true');
    if(this.userType === 'employer'){
      this.skillsService.invalidateSkillsCache();
    }
    this.store.dispatch(logOutAction());
  }

  ionViewWillLeave() {
    this.subscription.unsubscribe();
    this.apiUrlSubscription.unsubscribe();
    this.stripeKeySubscription.unsubscribe();
  }

  hideDemoBanner() {
    this.showDemoBanner = false;
  }

  async exitDemoMode() {
    await this.logout();
    this.configService.revertToDefault();
    alert('Exiting Demo Mode');
  }
}

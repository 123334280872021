import { Component, OnInit, Input, OnChanges, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { pluck, tap, map } from 'rxjs/operators';
import { MenuController, NavController } from '@ionic/angular';
import { Subscription, of } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { IAuthState } from 'src/modules/authentication/+store/auth.state';
import { getNotificationCount, getUserInfo } from 'src/modules/authentication/+store/auth-selector';
import { IUser } from 'src/modules/authentication/interfaces/user.interface';
import { LocalStorageService } from '../../services/local-storage.service';

@Component({
  selector: 'shared-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnChanges, OnDestroy {
  @Input() showMenuOption: boolean;
  @Input() showBackButton: boolean;
  @Input() subTitle: string;
  @Input() backPath: string;
  @Input() staffer: boolean;
  @Input() isCompany: boolean;

  isStaffer: boolean;
  userData: IUser;
  showMenu = of(false);
  userSubscription$?: Subscription;
  readonly user = this.authStore.pipe(select(getUserInfo));

  showNotification = this.route.data.pipe(pluck('showNotification'));
  notificationSubscription = this.authStore.pipe(select(getNotificationCount)).subscribe(async (result) => {
    this.notificationCount = result;
  });
  staffyLogo: string; 
  staffyIcon: string;
  notificationCount: number;
  constructor(
    private route: ActivatedRoute,
    private menu: MenuController,
    private navCtrl: NavController,
    private authStore: Store<IAuthState>,
    private localStorageService: LocalStorageService

  ) { }

  ngOnInit() {
    this.checkForMenuBtns();
    this.fetchStaffyLogo();

    if(this.isCompany) {
      this.isStaffer = false
      return;
    }

    this.userSubscription$ = this.user.subscribe(async (user) => {
      this.userData = user;
      if(this.userData) {
        if(this.userData.type == 'staffer') {
          this.isStaffer = true;
        }
        else {
          this.isStaffer = false;
        } 
        await this.fetchStaffyLogo();
      } else {
        this.isStaffer = true;
      }
    })
  }

  //Fetch staffy header logo according to selected expertise 
  async fetchStaffyLogo() {
    this.staffyLogo = await this.localStorageService.getStaffyLogo();
    this.staffyIcon = await this.localStorageService.getStaffyIcon();
  }

  ngOnChanges() {
    this.checkForMenuBtns();
  }

  ngOnDestroy() {
    if(this.notificationSubscription) {
      this.notificationSubscription.unsubscribe();
    }
    
    if(this.userSubscription$) {
      this.userSubscription$.unsubscribe();
    }
  }

  checkForMenuBtns() {
    this.showMenu = this.route.data.pipe(pluck('showMenu'), map((showMenu) => showMenu || this.showMenuOption),
      tap((showMenu, ) => this.menu.enable(showMenu, 'side-menu')));
  }

  openNotification() {

    this.user.subscribe((user: IUser) => {
     if (user.type === 'staffer') {
        this.isStaffer = true;
        this.navCtrl.navigateForward('talent-dashboard/notifications');
      }
      else if (user.type === 'employer') {
        this.isStaffer = false;
        this.navCtrl.navigateForward('company-dashboard/notifications');

      }

    }).unsubscribe();
  }
  navBack() {
    if(this.backPath) 
      this.navCtrl.navigateForward(this.backPath);
    else 
      this.navCtrl.pop();
  }

}

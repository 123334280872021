import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { StarRatingComponent } from '@tahashahid/ng-starrating';
import { ImagesService } from 'src/modules/shared/services/images.service';
import { IUser } from 'src/modules/authentication/interfaces/user.interface';
import { UtilService } from 'src/modules/shared/services/util.services';

@Component({
  selector: 'app-missing-rating-card',
  templateUrl: './missing-rating-card.component.html',
  styleUrls: ['./missing-rating-card.component.scss'],
})
export class MissingRatingCardComponent implements OnInit {
  rating: number;
  @Input() homeScreenMissingRating?= false;
  @Input() job: {
    jobId: string,
    distanceFromShift: string,
    skill: string,
    entityName: string,
    shiftStartTime: number,
    shiftEndTime: number,
    staffer: IUser,
    companyAddress?: {
      street: string,
      city: string,
      province: string,
      code: string,
      latitude: number,
      longitude: number,
      default: boolean
    }
  };
  @Output() rateNow = new EventEmitter();
  profilePicture: string;
  disableRateBtn = true;
  constructor(
    private imagesService: ImagesService,
    private utilService: UtilService
  ) { }

  ngOnInit() {
    this.profilePicture = this.imagesService.getProfilePic(this.job.staffer.profileUrl);;

  }

  onRate(event: { oldValue: number, newValue: number, starRating: StarRatingComponent }) {
    if (event.newValue) {
      this.rating = event.newValue;
      this.disableRateBtn = false;
    }
  }

  showLocationOnMap(lat: number, lng: number, name: string) {
    this.utilService.launchAddressOnMap(lat, lng, name);
  }

  submit() {
    if (this.disableRateBtn) {
      return;
    }
    this.rateNow.emit({
      stafferId: this.job.staffer._id,
      rating: this.rating,
      jobId: this.job.jobId,
      skill: this.job.skill
    });
  }

}

<div id = "drawer" class = "drawer" #drawer>
  <div class = "ion-text-center" (click) = "drawerVisibility()">
    <ion-button class = "drawer-pull" fill = "clear" expand = "full" >
      <i class = "fa fa-chevron-down"></i>
    </ion-button>

    <div class = "staffy-text">
      <img [src] = "staffyLogo"  [ngClass] = "{'logo-spacing': (staffyLogo?.includes('hospitality'))}">
      <div class = "regular-bold">Notifications</div>
    </div>
  </div>

  
  
  <div *ngIf="!notifications && isLoadingComplete" class = "no-shifts">
    <app-no-context-found [noShiftsData] = "noShifts"></app-no-context-found>
  </div>

  <div class = "wrapper" #wrapper>
    <div *ngIf = "userData?.profile_approved == true && userData?.type == 'staffer'">
      <div class = "container" *ngIf="message">
        <div class="text-section">
          <p class  = "general-book orange">
            {{ currentDate }}
          </p>
          
          <p class="name" class  = "general-bold">
            Message of the day
          </p>
          <p class = "general-regular">
            {{ message }}
          </p>
        </div>
    
        <div class = "button-section" >
          <ion-button (click) = "showMOTD()">See details</ion-button>
        </div>
      </div>
    </div>
    <div *ngIf="notifications">
      <div *ngFor="let notification of notifications | keyvalue: returnZero">
        <div  *ngFor="let notify of notification.value">
          
          <!-- <shared-notification-card style="width: 100%" [userData]="userData"
                  [hour24PayAvailableBadge]="hour24PayAvailableBadge" [settings]="settings"
                  [notification]="notify">
                </shared-notification-card> -->
                <shared-notification-card *ngIf="!notify.groupJobId" style="width: 100%" [userData]="userData"
                  [hour24PayAvailableBadge]="hour24PayAvailableBadge" [settings]="settings"
                  (acceptNonRecurringBroadcastRequest)="acceptNonRecurringBroadcastRequest($event)"
                  (rejectStafferBroadcastRequest)="rejectStafferBroadcastRequest($event)"
                  (seeAvailableJobs)="seeAvailableJobs()" (updateUser)="updateUser($event)"
                  (confirmNonRecurringShift)="confirmNonRecurringShift($event, notify)"
                  (unApplyFromNonRecurringShift)="unApplyFromNonRecurringShift($event)"
                  (rejectNonRecurringJob)="rejectNonRecurringJob($event)"
                  (stopTimerOnNonRecurringJob)="stopTimerOnJob($event)" (submitRating)="submitRating($event)"
                  (stafferStillWorkingOnNonRecurringJob)="stafferStillWorkingOnJob($event)"
                  (hireStafferForTheNonRecurringJob)="hireStafferForTheNonRecurringJob($event)"
                  (acceptNonRecurringHireRequest)="acceptNonRecurringHireRequest($event)" (click)="cardClicked(notify)"
                  [notification]="notify"
                  (hideNotificationDrawer)="drawerVisibility('/talent-dashboard/shift-details')">
                </shared-notification-card>
                <shared-notification-card-recurring *ngIf="notify.groupJobId" style="width: 100%" [userData]="userData"
                  [hour24PayAvailableBadge]="hour24PayAvailableBadge"  [settings]="settings"
                  (acceptRecurringBroadcastRequest)="acceptRecurringBroadcastRequest($event)"
                  (rejectStafferRecurringBroadcastRequest)="rejectStafferRecurringBroadcastRequest($event)"
                  (confirmRecurringShift)="confirmRecurringShift($event, notify)" (updateUser)="updateUser($event)"
                  (acceptRecurringHireRequest)="acceptRecurringHireRequest($event)"
                  (rejectRecurringHireRequest)="rejectRecurringHireRequest($event)"
                  (stafferStillWorkingOnRecurringJob)="stafferStillWorkingOnJob($event)"
                  (stopTimerOnRecurringJob)="stopTimerOnJob($event)" (submitRating)="submitRating($event)"
                  (hireStafferForTheRecurringJob)="hireStafferForTheRecurringJob($event)"
                  (unApplyFromRecurringShift)="unApplyFromRecurringShift($event)" (click)="cardClicked(notify)"
                  [notification]="notify"
                  (hideNotificationDrawer)="drawerVisibility('/talent-dashboard/shift-details')">
                </shared-notification-card-recurring>
        </div>
      </div>
      <ion-button *ngIf="!notificationId" expand="full" color="basic" class=" clearNotification"
        (click)='clear()'>
        {{ 'NOTIFICATIONS.Clear Previous Notifications' | translate }}
      </ion-button>
    </div>
  </div>
</div>